import { EyeOpenIcon, EyeClosedIcon } from '@radix-ui/react-icons'
import { Magicpen } from 'iconsax-react'
import * as React from 'react'
import { useState } from 'react'

import { cn } from '@/lib/utils'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  maskFormat?: string
  showPassIcon?: boolean
  showEdditIcon?: boolean
  desactive?: boolean
  icon?: any
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      maskFormat,
      showPassIcon,
      desactive,
      showEdditIcon,
      icon,
      ...props
    },
    ref,
  ) => {
    const [showPass, setShowPass] = useState(false)
    const togglePasswordVisibility = () => {
      setShowPass((prevShowPass) => !prevShowPass)
    }
    return (
      <div className="flex flex-row-reverse content-end">
        <input
          type={type === 'password' ? (showPass ? 'text' : 'password') : type}
          className={cn(
            'flex h-11 w-full rounded-md border border-neutralColor30 bg-baseColorHighlight px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutralColor60 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
            className,
          )}
          ref={ref}
          {...props}
        />
        {showPassIcon && (
          <button
            className="flex absolute mt-4 mr-3"
            type="button"
            onClick={togglePasswordVisibility}
          >
            {showPass ? (
              <EyeOpenIcon
                width={18}
                height={17}
                color={weCheckPalette.neutralColor}
              />
            ) : (
              <EyeClosedIcon
                width={18}
                height={17}
                color={weCheckPalette.neutralColor}
              />
            )}
          </button>
        )}
        {icon && icon}
        {showEdditIcon && (
          <button
            className="flex absolute mt-3 mr-3"
            type="button"
            onClick={togglePasswordVisibility}
          >
            {desactive ? (
              <Magicpen
                width={5}
                height={5}
                color={weCheckPalette.neutralColor30}
              />
            ) : (
              <Magicpen
                width={5}
                height={5}
                color={weCheckPalette.neutralColor}
              />
            )}
          </button>
        )}
      </div>
    )
  },
)
Input.displayName = 'Input'

export { Input }

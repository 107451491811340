import React from 'react'

interface ReportSettingsCardTypes {
  title: string
  value: boolean
}

export const ReportSettingsCard = ({
  title,
  value,
}: ReportSettingsCardTypes) => {
  return (
    <div className="w-full h-[22px] flex justify-between items-center">
      <p className="text-base font-medium text-neutralColor80">{title}</p>
      <p
        className={`text-sm font-medium tracking-tight ${
          value ? 'text-[#1bb058]' : 'text-[#e84040]'
        }`}
      >
        {value ? 'Yes' : 'No'}
      </p>
    </div>
  )
}

import { createSelector } from '@reduxjs/toolkit'

import type { RootState } from '@/store/store'

import type { ConfigurationItem } from './Admin.types'

export const selectConfiguration = (
  configuration: ConfigurationItem | undefined,
) => configuration

export const selectReportInfo = (state: RootState) => state.reportInfo

export const selectStatusConfiguration = createSelector(
  selectConfiguration,
  (configuration?: ConfigurationItem) => configuration?.status,
)

export const selectRoleConfiguration = createSelector(
  selectConfiguration,
  (configuration?: ConfigurationItem) => configuration?.staffRoles,
)

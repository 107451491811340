import React from 'react'

import { WeScreen } from '@/components/WeScreen/WeScreen'
import { useListPaginatedOfTopicsQuery } from '@/store/api/Topics/Topics.query'

import { TopicsTable } from './components/TopicsTable'

export const Topics = () => {
  const { data, isLoading, isError } = useListPaginatedOfTopicsQuery({})

  return (
    <WeScreen
      title="Topics"
      isError={isError}
      isLoading={isLoading}
      useHeaderBackButton={false}
      useNextButton={false}
    >
      <TopicsTable data={data} />
    </WeScreen>
  )
}

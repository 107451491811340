import { More } from 'iconsax-react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { ConfirmActionPopUp } from '@/components/ConfirmActionPopUp/ConfirmActionPopUp'
import { Button } from '@/components/ui/button'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
} from '@/components/ui/select'
import { AppRoutes } from '@/constants/appRoutes'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import {
  useDeletePostMutation,
  useDeleteWebSearchPostMutation,
} from '@/store/api/Subjects/Subjects.query'
import { selectSubjectId } from '@/store/api/Subjects/Subjects.selector'
import type { PostsList } from '@/store/api/Subjects/Subjects.types'
import { setEditPostToken } from '@/store/api/Subjects/SubjectsGuards.slice'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

interface PostActionsTypes {
  post: PostsList
  id: string
}

export const PostActions = ({ post, id }: PostActionsTypes) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const subjectId = useSelector(selectSubjectId)

  const [deletePost, { isLoading: isLoadingDeletePost }] =
    useDeletePostMutation()
  const [deleteWebSearchPost, { isLoading: isLoadingDeleteWebSearchPost }] =
    useDeleteWebSearchPostMutation()

  const handleDeletePost = async () => {
    try {
      await deletePost({
        id: id,
        subjectId: subjectId ?? '',
        postId: post.id,
      }).unwrap()
      toast.success('Post deleted')
    } catch (e) {
      catchWithSentry(e)
      toast.error('Error deleting Post')
    }
  }
  const handleDeleteWebSearchPost = async () => {
    try {
      await deleteWebSearchPost({
        subjectId: subjectId ?? '',
        postId: post.id,
      }).unwrap()
      toast.success('Web Post deleted')
    } catch (e) {
      catchWithSentry(e)
      toast.error('Error deleting Web Post')
    }
  }

  const handleEditPost = () => {
    navigate(AppRoutes.editPost, { state: post })
    dispatch(setEditPostToken(true))
  }
  return (
    <Select>
      <SelectTrigger
        className="w-10 h-10 border-none shadow-none p-1 "
        hiddenArrow
      >
        <More color={weCheckPalette.neutralColor} size={30} />
      </SelectTrigger>
      <SelectContent className="w-[224px]">
        <SelectGroup>
          <SelectLabel>Actions</SelectLabel>
          <SelectSeparator />
          <Button
            variant={'outline'}
            onClick={handleEditPost}
            className="border-none shadow-none p-0 w-full"
          >
            <p className="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2  outline-none focus:bg-primaryColor5 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 h-[35px] text-neutralColor60 text-sm font-medium tracking-tight hover:bg-primaryColor5 text-left">
              Edit
            </p>
          </Button>
          <ConfirmActionPopUp
            TriggerElement={<p className="text-dynamicRed">Delete</p>}
            onClick={
              post.platform === 'WebSearch'
                ? handleDeleteWebSearchPost
                : handleDeletePost
            }
            Title="Delete Post"
            Description="You are about to permanently delete this post. This action cannot be reversed. Deleting this post will remove all related data from the report."
            customTriggerStyle="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2 pr-8 outline-none focus:bg-primaryColor5 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 h-[35px] p-2 text-neutralColor60 text-sm font-medium tracking-tight hover:bg-primaryColor5"
            ConfirmText="Checking this box confirms my agree"
            isLoading={isLoadingDeletePost || isLoadingDeleteWebSearchPost}
          />
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}

import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { BackHeader } from '@/components/BackHeader/BackHeader'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Textarea } from '@/components/ui/text-area'
import { AppRoutes } from '@/constants/appRoutes'
import { useCreateNewTopicMutation } from '@/store/api/Topics/Topics.query'
import type {
  createNewTopicsRequest,
  translation,
} from '@/store/api/Topics/Topics.types'
import type {
  createTopicSchemaType,
  createTopicTraslationSchemaType,
} from '@/utils/schemas'
import {
  createTopicSchema,
  createTopicTranslationSchema,
} from '@/utils/schemas'

export const NewTopic = () => {
  const navigate = useNavigate()
  const [translations, setTranslations] = useState<translation[]>([])
  const [createNewTopic, { isLoading: isLoadingCreateNewTopic }] =
    useCreateNewTopicMutation()
  const [selectedLeng, setSelectedLeng] = useState<string>()

  const handleLengChange = (value: string) => {
    setSelectedLeng(value)
  }
  const handleCreateNewTopic = async (data: createTopicSchemaType) => {
    try {
      const requestInfo: createNewTopicsRequest = {
        name: data.name,
        enterprise: data.enterprise,
        promp: data.promp,
        translations: translations,
      }
      await createNewTopic(requestInfo).unwrap()
      toast.success('Topic created successfully.')
      navigate(AppRoutes.topics)
    } catch (e) {
      toast.error('Error creating new topic.')
      console.error(e)
    }
  }

  const createTopicForm = useForm<createTopicSchemaType>({
    resolver: zodResolver(createTopicSchema),
    defaultValues: {
      name: '',
      enterprise: '',
      promp: '',
    },
    mode: 'onChange',
  })

  const createTopicTranslationForm = useForm<createTopicTraslationSchemaType>({
    resolver: zodResolver(createTopicTranslationSchema),
    defaultValues: {
      translateName: '',
      description: '',
      keywords: '',
    },
    mode: 'onChange',
  })

  const onSubmitCreateTopic = (data: createTopicSchemaType) => {
    handleCreateNewTopic(data)
  }

  const onSubmitAddTranslation = (data: createTopicTraslationSchemaType) => {
    const newTranslation: translation = {
      language: selectedLeng || 'en',
      name: data.translateName,
      description: data.description,
      keywords: data.keywords.split(',').map((keyword) => keyword.trim()),
    }
    setTranslations([...translations, newTranslation])
    createTopicTranslationForm.reset()
    toast.success('Translation added successfully.')
  }

  return (
    <div>
      <div className="flex flex-row items-center">
        <BackHeader
          backText=""
          onPressBack={() => navigate(AppRoutes.topics)}
        />
      </div>
      <Card className="mt-8">
        <div className="p-6">
          <div className="flex justify-between mb-1 lg:flex-row flex-col-reverse gap-4">
            <p className="text-neutral-800 text-xl font-medium">
              Create New Topic
            </p>
          </div>
          <div className="lg:mb-8 mb-3">
            <p className="text-neutral-500 text-base font-normal">
              Enter the data of the new topic you want to create.
            </p>
          </div>
          <div className="flex flex-col gap-6">
            <div className="w-3/4 flex flex-col gap-3">
              <Form {...createTopicForm}>
                <form
                  onSubmit={createTopicForm.handleSubmit(onSubmitCreateTopic)}
                  id="createTopicForm"
                  className="flex flex-col gap-2"
                >
                  <FormField
                    control={createTopicForm.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>Name*</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="Name" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={createTopicForm.control}
                    name="enterprise"
                    render={({ field }) => (
                      <FormItem className="w-full ">
                        <FormLabel>Enterprise*</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="Enterprise" />
                        </FormControl>
                        <FormMessage data-name="dob" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={createTopicForm.control}
                    name="promp"
                    render={({ field }) => (
                      <FormItem className="w-full mr-6">
                        <FormLabel>Promp*</FormLabel>
                        <FormControl>
                          <Textarea {...field} placeholder="" />
                        </FormControl>
                        <FormMessage data-name="promp" />
                      </FormItem>
                    )}
                  />
                </form>
              </Form>
            </div>

            <div className="flex flex-col gap-4">
              <p className="text-neutral-800 text-xl font-medium my-2">
                Topics Translations
              </p>
              <Form {...createTopicTranslationForm}>
                <form
                  onSubmit={createTopicTranslationForm.handleSubmit(
                    onSubmitAddTranslation,
                  )}
                  id="addTranslationForm"
                  className="flex flex-col gap-2"
                >
                  <FormLabel className="mb-1">Language*</FormLabel>

                  <Select onValueChange={handleLengChange} value={selectedLeng}>
                    <SelectTrigger className="w-full h-10">
                      <SelectValue placeholder="Select Language" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value={'en'}>English</SelectItem>
                        <SelectItem value={'es'}>Spanish</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  <FormField
                    control={createTopicTranslationForm.control}
                    name="translateName"
                    render={({ field }) => (
                      <FormItem className="w-full mr-6">
                        <FormLabel>Name*</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="Name" />
                        </FormControl>
                        <FormMessage data-name="translateName" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={createTopicTranslationForm.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem className="w-full mr-6">
                        <FormLabel>Description*</FormLabel>
                        <FormControl>
                          <Textarea {...field} placeholder="Description" />
                        </FormControl>
                        <FormMessage data-name="description" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={createTopicTranslationForm.control}
                    name="keywords"
                    render={({ field }) => (
                      <FormItem className="w-full mr-6">
                        <FormLabel>Keywords*</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            placeholder="Keywords (comma separated)"
                          />
                        </FormControl>
                        <FormMessage data-name="keywords" />
                      </FormItem>
                    )}
                  />
                </form>
              </Form>
              <div>
                <Button
                  type="submit"
                  form="addTranslationForm"
                  className="h-[46px] px-5 py-3 bg-neutral-800 rounded-lg"
                  disabled={!createTopicTranslationForm.formState.isValid}
                >
                  <p>Add translation</p>
                </Button>
                {translations.length > 0 && (
                  <div className="flex flex-col gap-4 mt-4">
                    <p className="text-neutral-800 text-xl font-medium">
                      Translations:
                    </p>
                    <div className="flex gap-6">
                      {translations.map((t, index) => (
                        <div key={index} className="mb-2">
                          <div className="flex gap-2">
                            <p className="text-neutral-500 text-base font-normal tracking-tight">
                              Language:
                            </p>
                            <p className="text-neutral-800 text-base font-semibold tracking-tight">
                              {t.language}
                            </p>
                          </div>

                          <div className="flex gap-2">
                            <p className="text-neutral-500 text-base font-normal tracking-tight">
                              Name:
                            </p>
                            <p className="text-neutral-800 text-base font-semibold tracking-tight">
                              {t.name}
                            </p>
                          </div>

                          <div className="flex gap-2">
                            <p className="text-neutral-500 text-base font-normal tracking-tight">
                              Description:
                            </p>
                            <p className="text-neutral-800 text-base font-semibold tracking-tight">
                              {t.description}
                            </p>
                          </div>

                          <div className="flex gap-2">
                            <p className="text-neutral-500 text-base font-normal tracking-tight">
                              Keywords:
                            </p>
                            <p className="text-neutral-800 text-base font-semibold tracking-tight">
                              {t.keywords?.join(', ')}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex gap-6">
              <Button
                isLoading={isLoadingCreateNewTopic}
                form="createTopicForm"
                type="submit"
                disabled={!createTopicForm.formState.isValid}
                className="h-[46px] px-5 py-3 bg-neutral-800 rounded-lg"
              >
                <p className="text-neutral-50 text-base font-medium tracking-wide">
                  Create Topic
                </p>
              </Button>
              <Button
                className="w-24 h-[46px] px-5 py-3 bg-zinc-100 rounded-lg"
                onClick={() => navigate(AppRoutes.dashboard)}
              >
                <p className="text-neutral-800 text-base font-medium tracking-wide">
                  Cancel
                </p>
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

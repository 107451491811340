import type { SidebarUserTypes } from './sidebarUser.types'

export const SidebarUser = ({
  userInitials,
  name,
  mail,
  isPending,
}: SidebarUserTypes) => {
  return (
    <div className="flex flex-row justify-between bg-transparent p-4">
      <div
        className={`flex border border-neutralColor30 justify-center items-center bg-baseColorHighlight  lg:p-0 rounded-full min-w-[38px] min-h-[38px] lg:mb-0 ${'mr-3 lg:mr-3'}`}
      >
        <p className="lg:text-xl text-l text-textNormal lg:h-7 tracking-tighter">
          {userInitials}
        </p>
      </div>
      <>
        <div className={`flex flex-col flex-1 justify-center`}>
          <div>
            <p className="text-sm font-medium tracking-tight truncate text-textNormal">
              {name}
            </p>
          </div>
          <div>
            <p className="text-sm font-medium tracking-tight truncate text-neutralColor60">
              {mail}
            </p>
          </div>
        </div>
        {isPending && (
          <div className="bg-chipBackground py-1 px-2 rounded h-7 ml-2 justify-center items-center">
            <p className="text-xs tracking-wider font-medium">Pending</p>
          </div>
        )}
      </>
    </div>
  )
}

import { useSelector } from 'react-redux'

import { selectPermissions, selectRole } from '@/store/api/Users/Users.selector'

interface RolesPermissions {
  handle_billing: boolean
  handle_enterprises: boolean
  handle_users: boolean
  view_reports: boolean
  handle_reports: boolean
}

export const useDisableBasedOnPermissions = () => {
  const userRole = useSelector(selectRole)
  const userPermissions = useSelector(selectPermissions)
  const checkPermissions = (permission: keyof RolesPermissions) =>
    userPermissions.includes(permission)

  return {
    handleBilling: checkPermissions('handle_billing'),
    handleEnterprises: checkPermissions('handle_enterprises'),
    handleUsers: checkPermissions('handle_users'),
    viewReports: checkPermissions('view_reports'),
    handleReports: checkPermissions('handle_reports'),
    modifyEnterprise:
      checkPermissions('handle_enterprises') &&
      (userRole === 'admin' || 'super_admin'),
    modifyUsers: checkPermissions('handle_users') && userRole !== 'sales',
    regenerateReports:
      checkPermissions('handle_reports') &&
      (userRole === 'super_admin' || 'it'),
  }
}

import { createSelector } from '@reduxjs/toolkit'

import type { RootState } from '@/store/store'

export const selectEnteprise = (state: RootState) => state.enterpriseSlice

export const selectEntepriseData = createSelector(
  [selectEnteprise],

  (enterpriseData) => enterpriseData || null,
)

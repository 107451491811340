import { Copy, Trash } from 'iconsax-react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import emptyAvatar from '@/assets/avatarEmptyState.svg'
import WebNewIcon from '@/assets/socialMediaLogos/websearch.svg?react'
import { ConfirmActionPopUp } from '@/components/ConfirmActionPopUp/ConfirmActionPopUp'
import { Button } from '@/components/ui/button'
import { AppRoutes } from '@/constants/appRoutes'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useDeleteSocialMediaMutation } from '@/store/api/Subjects/Subjects.query'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import { ActionsHeader } from './ActionsHeader'
import { SocialMetrics } from './SocialMetrics'

export const ReportDetailsHeader = ({
  fullName,
  profileImage,
  showActions = false,
  showDelete = false,
  primaryButtonTitle,
  secondaryButtonTitle,
  primaryButtonOnClick,
  secondaryButtonOnClick,
  followers,
  following,
  posts,
  id,
  subjectId,
  bio,
  secondaryButtonChildren,
  primaryButtonChildren,
  showCopyId,
  wecheckScraped = false,
  imgCustomPadding,
  isWebNewOrAdverseMedia,
  isPrivate,
}: {
  fullName: string
  profileImage: string
  showActions?: boolean
  showDelete?: boolean
  primaryButtonTitle?: string
  secondaryButtonTitle?: string
  primaryButtonOnClick?: any
  secondaryButtonOnClick?: any
  followers?: number
  following?: number
  posts?: number
  id?: string
  subjectId?: string
  bio?: string
  secondaryButtonChildren?: any
  primaryButtonChildren?: any
  showCopyId?: boolean
  wecheckScraped?: boolean
  imgCustomPadding?: number
  isWebNewOrAdverseMedia?: boolean
  isPrivate?: boolean
}) => {
  const navigate = useNavigate()

  const [imageSrc, setImageSrc] = useState(profileImage || emptyAvatar)

  const [deleteSocialMedia, { isLoading }] = useDeleteSocialMediaMutation()

  const handleDeleteSocialMedia = async () => {
    try {
      await deleteSocialMedia({
        id: id || '',
        subjectId: subjectId || '',
      }).unwrap()
      toast.success('Social Media deleted')
      navigate(AppRoutes.socialMedia)
    } catch (e) {
      catchWithSentry(e)
      toast.error('Error deleting Social Media')
    }
  }

  const copySubjectId = async () => {
    try {
      await navigator.clipboard.writeText(subjectId || '')
      toast.success('Subject ID copied to clipboard.')
    } catch (err) {
      toast.error('Error when copying subject id, try again.')
    }
  }

  const textEllipsis = (text: string, maxLength: number) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text
  }

  return (
    <div className="w-full h-min-[108px] p-4 flex justify-between items-center bg-baseColorHighlight rounded-xl">
      <div className="flex gap-6 items-center">
        {isWebNewOrAdverseMedia ? (
          <div className="w-[105px] h-[76px] bg-[#2798ea]/5 rounded-full border border-[#2798ea]/30 items-center justify-center flex">
            <WebNewIcon />
          </div>
        ) : (
          <div
            className={
              'w-[105px] h-[76px] overflow-hidden bg-primaryColor5 rounded-full border border-primaryColor30 items-center'
            }
          >
            <img
              src={imageSrc}
              style={{ padding: imgCustomPadding }}
              alt={fullName}
              className="w-full h-full"
              onError={() => setImageSrc(emptyAvatar)}
            />
          </div>
        )}

        <div className="flex flex-col gap-1 w-full">
          <p className="text-neutralColor text-2xl font-medium">
            {fullName}{' '}
            {isPrivate && (
              <span className="text-neutralColor60 text-xl font-normal">
                (Private)
              </span>
            )}{' '}
          </p>
          {subjectId && showCopyId && (
            <div className="flex items-center gap-3">
              <p className="text-neutralColor60 text-base font-normal">
                Subject ID: {subjectId}
              </p>
              <Copy
                size={20}
                color={weCheckPalette.neutralColor}
                onClick={copySubjectId}
              />
            </div>
          )}
          {followers && following && posts && (
            <SocialMetrics
              following={following}
              followers={followers}
              posts={posts}
            />
          )}
          {bio && (
            <p className="text-base font-normal text-neutralColor60 text-nowrap text-ellipsis">
              {textEllipsis(bio, 85)}
            </p>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex justify-end">
          {showActions && subjectId && id && (
            <ActionsHeader
              subjectId={subjectId}
              wecheckScraped={wecheckScraped}
            />
          )}
          {showDelete && subjectId && id && (
            <ConfirmActionPopUp
              Title="Are You Sure You Want to Delete This Account?"
              Description="Deleting this account will permanently remove all associated posts. You can only recover the original data by resetting the report. This action cannot be undone."
              ConfirmText="Checking this box confirms my agree"
              onClick={handleDeleteSocialMedia}
              isLoading={isLoading}
              TriggerElement={
                <Button
                  className="border-none shadow-none"
                  variant="outline"
                  size="icon"
                >
                  <Trash
                    size="24"
                    color={weCheckPalette.dynamicRed}
                    variant="Bold"
                  />
                </Button>
              }
              customTriggerStyle="w-8 h-8 mb-4"
            />
          )}
        </div>
        <div className="flex gap-3">
          {secondaryButtonTitle && (
            <Button
              onClick={secondaryButtonOnClick}
              className="rounded-xl"
              variant={'secondary'}
            >
              {secondaryButtonTitle}
            </Button>
          )}
          {primaryButtonTitle && (
            <Button onClick={primaryButtonOnClick} className="rounded-xl">
              <p>{primaryButtonTitle}</p>
            </Button>
          )}
          {secondaryButtonChildren}
          {primaryButtonChildren}
        </div>
      </div>
    </div>
  )
}

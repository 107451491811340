import { createSlice } from '@reduxjs/toolkit'

export interface userSliceState {
  firstname: string
  lastname: string
  email: string
  enterprise: string
  phoneNumber: string
  role: string
  permissions: string
  enterpriseName: string
  credits: number
}

export const userInfoSliceInitialState: userSliceState = {
  firstname: '',
  lastname: '',
  email: '',
  enterprise: '',
  phoneNumber: '',
  role: '',
  permissions: '',
  enterpriseName: '',
  credits: 0,
}

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: userInfoSliceInitialState,
  reducers: {
    setUserInfo: (state, action) => {
      const userInfo = action.payload
      state.firstname = userInfo.firstname
      state.lastname = userInfo.lastname
      state.email = userInfo.email
      state.enterprise = userInfo.enterprise
      state.phoneNumber = userInfo.phoneNumber
      state.role = userInfo.role
      state.permissions = userInfo.permissions
      state.enterpriseName = userInfo.enterprise.name
      state.credits = userInfo.enterprise.credits
    },
  },
})
export const { setUserInfo } = userInfoSlice.actions

export default userInfoSlice.reducer

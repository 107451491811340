import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import React from 'react'
import ReactDOM from 'react-dom/client'

import './index.css'
import App from './App'
import env from './env/env'

const firebaseConfig = {
  apiKey: env.FIREBASE_CONFIG_APIKEY,
  authDomain: env.FIREBASE_CONFIG_AUTHDOMAIN,
  projectId: env.FIREBASE_CONFIG_PROJECTID,
  storageBucket: env.FIREBASE_CONFIG_STORAGEBUCKET,
  messagingSenderId: env.FIREBASE_CONFIG_MESSAGINGSENDERID,
  appId: env.FIREBASE_CONFIG_APPID,
  measurementId: env.FIREBASE_CONFIG_MEASUREMENTID,
}

const app = initializeApp(firebaseConfig)
app.automaticDataCollectionEnabled = true
getAnalytics(app)

const root = ReactDOM.createRoot(document.getElementById('root')!)

if (env.NODE_ENV === 'mockserver') {
  // Prepare MSW in a Service Worker
  import('../mocks/browser')
    .then(({ worker }) => {
      worker.start()
    })
    // Launched mock server, and then start client React app
    .then(() => root.render(<App />))
} else {
  // Production
  root.render(<App />)
}

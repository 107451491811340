import { zodResolver } from '@hookform/resolvers/zod'
import { AddCircle } from 'iconsax-react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import type { z } from 'zod'

import { Chip } from '@/components/Chip/Chip'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { Form, FormField, FormLabel } from '@/components/ui/form'
import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetFooter,
} from '@/components/ui/sheet'
import { setEditPostSelectedFlags } from '@/store/api/Subjects/Subjects.slice'
import { useListPaginatedOfTopicsQuery } from '@/store/api/Topics/Topics.query'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import { changeTopicsSchema } from '@/utils/schemas'

import { additionalFlags } from './flags'

type ChangeTopicsForm = z.infer<typeof changeTopicsSchema>

const getFlagsAsBooleanObj = (topics: string[], selectedFlags: string[]) => {
  const flagsObj: Record<string, boolean> = {}
  topics.forEach((topic) => {
    flagsObj[topic] = selectedFlags.includes(topic)
  })
  return flagsObj
}

export const EditPostLoadFlagSheet = ({
  currentPostFlags,
}: {
  currentPostFlags: string[]
}) => {
  const { data: topicsData } = useListPaginatedOfTopicsQuery({})
  const [open, setOpen] = useState(false)

  const dispatch = useDispatch()
  const topicsFromQuery = topicsData?.info.list.map((topic) => topic.name)

  const allTopics = [...(topicsFromQuery || []), ...additionalFlags]

  const transformedFlags = getFlagsAsBooleanObj(allTopics, currentPostFlags)

  const form = useForm<ChangeTopicsForm>({
    resolver: zodResolver(changeTopicsSchema),
    defaultValues: transformedFlags,
  })

  if (!topicsData) {
    return null
  }

  const { control, handleSubmit } = form

  const onSubmit = (data: any) => {
    const selectedFlags = allTopics.filter((topic) => data[topic])
    dispatch(setEditPostSelectedFlags(selectedFlags))
    setOpen(false)
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger className="hover:bg-primaryColor5 rounded-xl">
        <Chip
          text="Add"
          icon={<AddCircle size={16} color={weCheckPalette.neutralColor60} />}
          isTag
          dashed
        />
      </SheetTrigger>
      <SheetContent className="bg-baseColorHighlight lg:w-1/3 flex flex-col gap-6 justify-between overflow-y-scroll">
        <Form {...form}>
          <form className="flex flex-col gap-y-5">
            <div className="flex flex-col gap-3">
              <p className="text-neutralColor text-xl font-medium">Post Flag</p>
              <p className="text-neutralColor60 text-sm font-medium tracking-tight">
                Add up to 2 Flags.
              </p>
            </div>
            <div className="flex flex-col gap-3">
              <p className="text-neutralColor80 text-base font-medium">
                Focused Topics
              </p>
              {topicsFromQuery?.map((topic) => (
                <FormField
                  key={topic}
                  control={control}
                  name={topic as keyof ChangeTopicsForm}
                  render={({ field }) => (
                    <div className="flex justify-between items-center">
                      <FormLabel className="text-neutralColor60 text-sm font-medium tracking-tight">
                        {topic}
                      </FormLabel>
                      <Checkbox
                        hiddenCheckIcon
                        className="rounded-full data-[state=checked]:bg-primaryColor border-4 border-staticWhite shadow-neutralColor80 shadow-sm"
                        checked={field.value || false}
                        onCheckedChange={field.onChange}
                      />
                    </div>
                  )}
                />
              ))}
            </div>

            <div className="border-b border-neutralColor30" />

            <div className="flex flex-col gap-3">
              <p className="text-neutralColor80 text-base font-medium">Flags</p>
              {additionalFlags.map((flag) => (
                <FormField
                  key={flag}
                  control={control}
                  name={flag as keyof ChangeTopicsForm}
                  render={({ field }) => (
                    <div className="flex justify-between items-center">
                      <FormLabel className="text-neutralColor60 text-sm font-medium tracking-tight">
                        {flag}
                      </FormLabel>
                      <Checkbox
                        hiddenCheckIcon
                        className="rounded-full data-[state=checked]:bg-primaryColor border-4 border-staticWhite shadow-neutralColor80 shadow-sm"
                        checked={field.value || false}
                        onCheckedChange={field.onChange}
                      />
                    </div>
                  )}
                />
              ))}
            </div>
          </form>
        </Form>
        <SheetFooter className="flex">
          <Button
            type="button"
            variant="secondary"
            className="w-full"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button onClick={handleSubmit(onSubmit)} className="w-full">
            Add
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}

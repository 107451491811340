import React from 'react'

import type { ICsvFile } from '@/store/api/Batch/Batch.types'

type TableProps = {
  data: ICsvFile[]
}

export const BatchTable: React.FC<TableProps> = ({ data }) => {
  if (data.length === 0) {
    return <p>No data available</p>
  }

  // Obtén las claves del primer objeto como encabezados de columna
  const headers = Object.keys(data[0])

  return (
    <div className="overflow-x-auto overflow-y-auto max-h-96">
      {' '}
      {/* Cambia la altura máxima según tus necesidades */}
      <table className="min-w-full divide-y divide-gray-200 bg-white border">
        <thead className="bg-gray-50">
          <tr>
            {headers.map((header) => (
              <th
                key={header}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {header.charAt(0).toUpperCase() + header.slice(1)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex} className="hover:bg-gray-100">
              {headers.map((header) => (
                <td key={header} className="py-2 px-4 border-b">
                  {row[header as keyof ICsvFile]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

import React from 'react'

interface SummaryCardTypes {
  title: string
  text?: string
}

export const SummaryCard = ({ title, text }: SummaryCardTypes) => {
  return (
    <div className="w-full p-3 bg-primaryColor5 rounded-2xl flex-col justify-start gap-3">
      <p className="text-neutralColor text-base font-medium">{title}</p>
      <p className="text-neutralColor60 text-sm font-medium tracking-tight">
        {text || '-'}
      </p>
    </div>
  )
}

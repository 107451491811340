import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { AppRoutes } from '@/constants/appRoutes'
import { useUploadProfilesMutation } from '@/store/api/Scrape/Scrape.query'
import type {
  SelectedProfile,
  ESocialMedias,
} from '@/store/api/Scrape/Scrape.types'
import type { PersonData } from '@/store/api/Subjects/Subjects.types'
import { generateId } from '@/utils/ids'

const socialMediaPatterns = {
  Facebook:
    /^(https?:\/\/)?(www\.)?(facebook\.com\/(profile\.php\?id=\d+|[A-Za-z0-9._-]+|p\/[A-Za-z0-9._-]+)\/?|web\.facebook\.com\/p\/[A-Za-z0-9._-]+\/?)$/,
  Twitter: /^(https?:\/\/)?(www\.)?(twitter\.com|x\.com)\/[A-Za-z0-9._-]+\/?$/, // Modificado para incluir x.com
  Instagram: /^(https?:\/\/)?(www\.)?instagram\.com\/[A-Za-z0-9._-]+\/?$/,
  LinkedIn:
    /^(https?:\/\/)?(www\.)?(linkedin\.com\/in\/[A-Za-z0-9._-]+\/?|linkedin\.com\/company\/[A-Za-z0-9._-]+\/?)$/,
  TikTok: /^(https?:\/\/)?(www\.)?tiktok\.com\/@[A-Za-z0-9._-]+\/?$/, // Patrón para TikTok
  Pinterest: /^(https?:\/\/)?(www\.)?pinterest\.com\/[A-Za-z0-9._-]+\/?$/, // Patrón para Pinterest
  // Agrega más patrones según sea necesario
}

const ProfileUploader = ({
  subjectId,
  personData,
}: {
  subjectId: string
  personData?: PersonData
}) => {
  const [profiles, setProfiles] = useState<SelectedProfile[]>([])
  const navigate = useNavigate()

  // Efecto para preestablecer los perfiles con la información de personData
  useEffect(() => {
    const initialProfiles: SelectedProfile[] = []

    if (personData?.twitterUrl) {
      initialProfiles.push({
        id: generateId(),
        url: personData.twitterUrl,
        name: 'Twitter',
        isPredefined: true, // Indica que es un perfil preestablecido
      })
    }
    if (personData?.facebookUrl) {
      initialProfiles.push({
        id: generateId(),
        url: personData.facebookUrl,
        name: 'Facebook',
        isPredefined: true, // Indica que es un perfil preestablecido
      })
    }
    if (personData?.instagramUrl) {
      initialProfiles.push({
        id: generateId(),
        url: personData.instagramUrl,
        name: 'Instagram',
        isPredefined: true, // Indica que es un perfil preestablecido
      })
    }
    if (personData?.linkedInUrl) {
      initialProfiles.push({
        id: generateId(),
        url: personData.linkedInUrl,
        name: 'LinkedIn',
        isPredefined: true, // Indica que es un perfil preestablecido
      })
    }

    if (personData?.tiktokUrl) {
      initialProfiles.push({
        id: generateId(),
        url: personData.tiktokUrl,
        name: 'TikTok',
        isPredefined: true, // Indica que es un perfil preestablecido
      })
    }

    setProfiles(initialProfiles)
  }, [personData])

  const handleInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target
    const newProfiles = [...profiles]
    newProfiles[index].url = value

    // Validar la URL y establecer la red social
    for (const [platform, pattern] of Object.entries(socialMediaPatterns)) {
      if (pattern.test(value)) {
        newProfiles[index].name = platform as ESocialMedias // Establecer la red social
        break
      } else {
        newProfiles[index].name = '' // Limpiar si no coincide
      }
    }

    setProfiles(newProfiles)
  }

  const handleAddProfile = () => {
    setProfiles([
      ...profiles,
      { url: '', name: '', id: generateId(), isPredefined: false },
    ]) // Indicar que es un perfil agregado por el usuario
  }

  const handleRemoveProfile = (index: number) => {
    const newProfiles = profiles.filter((_, i) => i !== index)
    setProfiles(newProfiles)
  }

  const [uploadProfiles, { isLoading }] = useUploadProfilesMutation()

  const handleSubmit = async () => {
    try {
      // Filtrar los perfiles para eliminar isPredefined antes de enviar
      const profilesToUpload = profiles.map(({ isPredefined, ...rest }) => rest)

      await uploadProfiles({
        subjectId: subjectId,
        profiles: profilesToUpload,
      })
      toast.success('Profiles uploaded successfully')
      navigate(AppRoutes.reports)
    } catch (error) {
      toast.error('Error on profiles upload')
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <h2>Upload Profiles</h2>
      {profiles.map((profile, index) => (
        <div key={index} className="flex w-full gap-6">
          <Input
            type="text"
            name="url"
            placeholder="URL"
            className="w-96"
            value={profile.url}
            onChange={(event) => handleInputChange(index, event)}
          />

          <Button onClick={() => handleRemoveProfile(index)}>Remove</Button>
        </div>
      ))}
      <Button onClick={handleAddProfile}>Add Profile</Button>
      <Button onClick={handleSubmit} isLoading={isLoading}>
        Submit
      </Button>
    </div>
  )
}

export default ProfileUploader

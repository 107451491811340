import { More } from 'iconsax-react'
import React from 'react'
import { toast } from 'sonner'

import { ConfirmActionPopUp } from '@/components/ConfirmActionPopUp/ConfirmActionPopUp'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
} from '@/components/ui/select'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import { Button } from '../ui/button'

interface CompanyUsersActionsTypes {
  id: string
  updateStatus: (role: string | null, status: string | null, id: string) => void
  currentStatus: string
}

export const ActionsSelector = ({
  id,
  updateStatus,
  currentStatus,
}: CompanyUsersActionsTypes) => {
  const copySubjectId = async () => {
    try {
      await navigator.clipboard.writeText(id)
      toast.success('Subject ID copied to clipboard.')
    } catch (err) {
      toast.error('Error when copying subject id, try again.')
    }
  }

  return (
    <Select>
      <SelectTrigger
        className="w-10 h-10 border-none shadow-none p-1 "
        hiddenArrow
      >
        <More color={weCheckPalette.neutralColor} size={30} />
      </SelectTrigger>
      <SelectContent className="w-[224px]">
        <SelectGroup>
          <SelectLabel>Actions</SelectLabel>
          <SelectSeparator />
          {currentStatus !== 'blocked' && (
            <ConfirmActionPopUp
              onClick={() => updateStatus(null, 'blocked', id)}
              TriggerText="Block"
              Title="Block User"
              Description="Are you sure you want to block this user? This will prevent them from accessing the platform and its features."
              customTriggerStyle="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2 pr-8 outline-none focus:bg-primaryColor5 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 h-[35px] p-2 text-neutralColor60 text-sm font-medium tracking-tight hover:bg-primaryColor5"
              hiddenCheckBox
            />
          )}
          {currentStatus !== 'deleted' && (
            <ConfirmActionPopUp
              TriggerElement={<p className="text-dynamicRed">Delete</p>}
              onClick={() => updateStatus(null, 'deleted', id)}
              Title="Delete User"
              Description="Are you sure you want to delete this user? This action is irreversible and will remove all associated data."
              customTriggerStyle="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2 pr-8 outline-none focus:bg-primaryColor5 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 h-[35px] p-2 text-neutralColor60 text-sm font-medium tracking-tight hover:bg-primaryColor5"
              hiddenCheckBox
            />
          )}
          <Button
            variant={'outline'}
            className="border-none shadow-none p-0 w-full"
            onClick={async () => copySubjectId()}
          >
            <p className="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2  outline-none focus:bg-primaryColor5 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 h-[35px] text-neutralColor60 text-sm font-medium tracking-tight hover:bg-primaryColor5 text-left">
              Copy User ID
            </p>
          </Button>
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}

import { useNavigate } from 'react-router-dom'

import { useDisableBasedOnPermissions } from './useDisableBasedOnPermissions'

export const useRedirectBasedOnPermissions = () => {
  const navigate = useNavigate()
  const { handleReports, handleEnterprises, handleUsers, viewReports } =
    useDisableBasedOnPermissions()

  const hasHandleReportsRedirect = () => {
    if (!handleReports) {
      navigate('/dashboard/users')
    }
  }

  const hasHandleEnterprisesRedirect = () => {
    if (!handleEnterprises) {
      navigate('/dashboard/users')
    }
  }
  const hasHandleUsersRedirect = () => {
    if (!handleUsers) {
      navigate('/dashboard/enterprises')
    }
  }
  const hasViewReportsRedirect = () => {
    if (!viewReports) {
      navigate('/dashboard/users')
    }
  }

  return {
    hasHandleReports: hasHandleReportsRedirect,
    hasHandleEnterprises: hasHandleEnterprisesRedirect,
    hasHandleUsers: hasHandleUsersRedirect,
    hasViewReports: hasViewReportsRedirect,
  }
}

import React from 'react'

import { Card } from '@/components/ui/card'
import type { IUploadCsvResponse } from '@/store/api/Batch/Batch.types'

export const ErrorBatchTable = ({
  uploadCsvData,
}: {
  uploadCsvData: IUploadCsvResponse[]
}) => {
  return (
    <div className="flex flex-col gap-6 w-full justify-center items-center max-xl:flex-col mt-8">
      <Card className="flex justify-between flex-col w-full h-full">
        {uploadCsvData && (
          <div>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Field
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Validation Errors
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {uploadCsvData.map((item, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {item.data.firstName} {item.data.lastName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {item.errors.map((error, idx) => (
                        <div key={idx}>{error.field}</div>
                      ))}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {item.errors.map((error, idx) => (
                        <ul key={idx}>
                          {error.validations.map((validation, idx2) => (
                            <li key={idx2}>{validation}</li>
                          ))}
                        </ul>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Card>
    </div>
  )
}

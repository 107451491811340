import { RTKTags, api } from '@/store/api'

import Env from '../../../env/env'

import type {
  UserContactUsRequest,
  UserInfoCrudResponse,
  UserInfoResponse,
} from './Users.types'

export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    userInfo: builder.query<UserInfoResponse, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v2/users/me`,
        method: 'GET',
      }),
      transformResponse: (response: UserInfoCrudResponse) => {
        return response.info
      },
      providesTags: [RTKTags.userInfo],
    }),
    userContact: builder.mutation<void, UserContactUsRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/forms/contact-us`,
        method: 'POST',
        body: {
          organizationName: request.organizationName,
          personName: request.personName,
          personEmail: request.personEmail,
          phoneNumber: request.phoneNumber,
          message: request.message,
        },
      }),
    }),
  }),
})

export const { useUserInfoQuery, useUserContactMutation } = usersApi

import { createSlice } from '@reduxjs/toolkit'

export interface uiSliceState {
  collapsed: boolean
}

export const uiSliceInitialState: uiSliceState = {
  collapsed: true,
}

const uiSlice = createSlice({
  name: 'ui',
  initialState: uiSliceInitialState,
  reducers: {
    collapseNavBar: (state) => {
      state.collapsed = true
    },
    expandNavBar: (state) => {
      state.collapsed = false
    },
  },
})
export const { collapseNavBar, expandNavBar } = uiSlice.actions

export default uiSlice.reducer

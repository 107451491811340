import { useLogout } from '@/hooks/useLogout'

export const SidebarLogout = () => {
  const { logout } = useLogout()
  return (
    <div className="flex justify-between items-center rounded-lg bg-transparent p-4">
      <div
        className="flex flex-row"
        onClick={logout}
        role="button"
        tabIndex={0}
      >
        <p
          className={`text-dynamicRed text-sm leading-6 text-logout transition duration-1000 opacity-100`}
        >
          Log Out
        </p>
      </div>
    </div>
  )
}

import { useState } from 'react'

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { useConfigurationQuery } from '@/store/api/Admin/Admin.query'

interface RoleSelectorTypes {
  currentRole: string
  updateRole: (role: string | null, status: string | null, id: string) => void
  id: string
  isLoading: boolean
}

export const RoleSelector = ({
  currentRole,
  updateRole,
  id,
  isLoading,
}: RoleSelectorTypes) => {
  const { data } = useConfigurationQuery()

  const [selectedRole, setSelectedRole] = useState(currentRole)

  const handleRoleChange = (value: string) => {
    setSelectedRole(value)
    updateRole(value, null, id)
  }

  return (
    <Select onValueChange={handleRoleChange} value={selectedRole}>
      <SelectTrigger
        className="w-min space-x-2 shadow-none p-0"
        disabled={isLoading}
      >
        <SelectValue placeholder="Select user role" />
      </SelectTrigger>
      <SelectContent className="w-[224px]">
        <SelectGroup>
          <SelectLabel>Enterprise Roles</SelectLabel>
          <SelectSeparator />
          {data?.enterpriseRoles.map((item: string) => (
            <SelectItem value={item}>
              {item === 'owner'
                ? 'Owner'
                : item === 'manager'
                  ? 'Manager'
                  : item === 'operational_user'
                    ? 'Operational'
                    : item === 'view_user' && 'Read Only'}
            </SelectItem>
          ))}
          <SelectSeparator />
          <SelectLabel>Staff Roles</SelectLabel>
          <SelectSeparator />
          {data?.staffRoles.map((item: string) => (
            <SelectItem value={item}>
              {item === 'super_admin'
                ? 'Super Admin'
                : item === 'admin'
                  ? 'Admin'
                  : item === 'sales'
                    ? 'Sales'
                    : item === 'it' && 'IT'}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}

import type { ReactElement } from 'react'
import { useState } from 'react'

import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'

interface ConfirmActionPopUpTypes {
  TriggerText?: string
  Title?: string
  Description?: string
  ConfirmText?: string
  onClick?: () => void
  isLoading?: boolean
  open?: boolean
  setOpen?: any
  customTriggerStyle?: string
  confirmTextRed?: boolean
  TriggerElement?: ReactElement
  hiddenCheckBox?: boolean
}

export const ConfirmActionPopUp = ({
  TriggerText,
  Title,
  Description,
  ConfirmText,
  onClick,
  isLoading,
  open,
  setOpen,
  customTriggerStyle,
  confirmTextRed,
  TriggerElement,
  hiddenCheckBox,
}: ConfirmActionPopUpTypes) => {
  const [check, setCheck] = useState(false)

  const handleCheck = (newChecked: boolean) => {
    setCheck(newChecked)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        className={`${
          customTriggerStyle
            ? customTriggerStyle
            : 'py-3 px-5 font-medium tracking-widest h-11 w-full flex'
        } `}
      >
        {TriggerText}
        {TriggerElement}
      </DialogTrigger>
      <DialogContent maxWidth="max-w-3xl">
        <DialogHeader className="flex gap-3">
          <DialogTitle className="text-2xl font-medium text-neutralColor">
            {Title}
          </DialogTitle>
          <DialogDescription className="text-base font-normal text-neutralColor">
            {Description}
          </DialogDescription>
        </DialogHeader>
        {!hiddenCheckBox && (
          <div className="flex gap-2 items-center">
            <Checkbox
              className="w-5 h-5 rounded-md border-neutral-600"
              onCheckedChange={handleCheck}
            />
            <p
              className={`font-medium text-sm tracking-tight  ${
                confirmTextRed ? 'text-dynamicRed' : 'text-neutralColor60'
              }`}
            >
              {ConfirmText}
            </p>
          </div>
        )}

        <DialogFooter>
          <DialogClose asChild>
            <Button type="button" className="h-[46px] px-5 py-3 rounded-xl">
              <p className="text-base font-medium">Cancel</p>
            </Button>
          </DialogClose>
          <Button
            isLoading={isLoading}
            onClick={onClick}
            disabled={hiddenCheckBox ? false : !check}
            type="button"
            variant="secondary"
            className="h-[46px] px-5 py-3 rounded-xl"
          >
            <p className="text-neutralColor text-base font-medium">Confirm</p>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

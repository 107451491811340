import { RTKTags, api } from '@/store/api'

import Env from '../../../env/env'

import type {
  getSubjectResponse,
  getSubjectRequest,
  updateSocialMediaInfoRequest,
  addNewSocialMediaRequest,
  getSubjectPostsResponse,
  getSubjectPostsRequest,
  deletePostRequest,
  deleteSocialMediaRequest,
  createNewPostRequest,
  modifyPostRequest,
  createNewWebSearchPostRequest,
  modifyWebSearchPostRequest,
  modifySummaryRequest,
  deleteWebSearchPostRequest,
  changeReportTopicsRequest,
} from './Subjects.types'

export const subjectsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSubject: builder.query<getSubjectResponse, getSubjectRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}`,
        method: 'GET',
        params: {},
      }),
      providesTags: [RTKTags.subjects, RTKTags.reports],
    }),
    updateSocialMediaInfo: builder.mutation<void, updateSocialMediaInfoRequest>(
      {
        query: (request: updateSocialMediaInfoRequest) => ({
          url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/social-media/${request.id}`,
          method: 'PATCH',
          body: {
            profileImgUrl: request.profileImgUrl,
            bio: request.bio,
            followers: request.followers,
            following: request.following,
            postsCount: request.postsCount,
            profileLink: request.profileLink,
            isPrivate: request.isPrivate,
            userName: request.userName,
          },
        }),
        invalidatesTags: [RTKTags.subjects],
      },
    ),
    addNewSocialMedia: builder.mutation<void, addNewSocialMediaRequest>({
      query: (request: addNewSocialMediaRequest) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/social-media`,
        method: 'POST',
        body: {
          userName: request.userName,
          profileLink: request.profileLink,
          profileImgUrl: request.profileImgUrl,
          postsCount: request.postsCount,
          name: request.name,
          following: request.following,
          followers: request.followers,
          bio: request.bio,
          isPrivate: request.isPrivate,
        },
      }),
      invalidatesTags: [RTKTags.subjects],
    }),
    getSubjectPosts: builder.query<
      getSubjectPostsResponse,
      getSubjectPostsRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/posts`,
        method: 'GET',
        params: {
          filterBy: request.filterBy,
          page: request.page,
          pageSize: 5000,
          platform: request.platform,
        },
      }),
      providesTags: [RTKTags.subjects],
    }),
    deletePost: builder.mutation<void, deletePostRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/social-media/${request.id}/posts/${request.postId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKTags.subjects],
    }),
    deleteSocialMedia: builder.mutation<void, deleteSocialMediaRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/social-media/${request.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKTags.subjects],
    }),
    createNewPost: builder.mutation<void, createNewPostRequest>({
      query: (request: createNewPostRequest) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/social-media/${request.id}/posts`,
        method: 'POST',
        body: {
          content: request.content,
          link: request.link,
          mediaUrl: request.mediaUrl,
          videoLink: request.videoLink,
          type: request.type,
          sentimentFlag: request.sentimentFlag,
          flaggedText: request.flaggedText,
          flags: request.flags,
          sentimentReason: request.sentimentReason,
          flagReason: request.flagReason,
          date: request.date,
          platform: request.platform,
        },
      }),
      invalidatesTags: [RTKTags.subjects],
    }),
    modifyPost: builder.mutation<void, modifyPostRequest>({
      query: (request: modifyPostRequest) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/social-media/${request.id}/posts/${request.postId}`,
        method: 'PATCH',
        body: {
          content: request.content,
          link: request.link,
          mediaUrl: request.mediaUrl,
          videoLink: request.videoLink,
          type: request.type,
          sentimentFlag: request.sentimentFlag,
          flags: request.flags,
          flaggedText: request.flaggedText,
          sentimentReason: request.sentimentReason,
          flagReason: request.flagReason,
          date: request.date,
        },
      }),
      invalidatesTags: [RTKTags.subjects],
    }),
    createNewWebSearchPost: builder.mutation<
      void,
      createNewWebSearchPostRequest
    >({
      query: (request: createNewWebSearchPostRequest) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/web-search`,
        method: 'POST',
        body: {
          content: request.content,
          flags: request.flags,
          flaggedText: request.flaggedText,
          flagReason: request.flagReason,
          link: request.link,
          mediaUrl: request.mediaUrl,
          videoLink: request.videoLink,
          sentimentReason: request.sentimentReason,
          sentimentFlag: request.sentimentFlag,
        },
      }),
      invalidatesTags: [RTKTags.subjects],
    }),
    modifyWebSearchPost: builder.mutation<void, modifyWebSearchPostRequest>({
      query: (request: modifyWebSearchPostRequest) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/web-search/${request.postId}`,
        method: 'PATCH',
        body: {
          content: request.content,
          link: request.link,
          mediaUrl: request.mediaUrl,
          videoLink: request.videoLink,
          sentimentFlag: request.sentimentFlag,
          flags: request.flags,
          flaggedText: request.flaggedText,
          sentimentReason: request.sentimentReason,
          flagReason: request.flagReason,
        },
      }),
      invalidatesTags: [RTKTags.subjects],
    }),
    deleteWebSearchPost: builder.mutation<void, deleteWebSearchPostRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/web-search/${request.postId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKTags.subjects],
    }),
    modifyPersonData: builder.mutation<
      void,
      { formData: FormData; subjectId: string }
    >({
      query: ({ formData, subjectId }) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${subjectId}/person-data`,
        method: 'PATCH',
        body: formData,
      }),
      invalidatesTags: [RTKTags.subjects],
    }),
    modifySummary: builder.mutation<void, modifySummaryRequest>({
      query: (request: modifySummaryRequest) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/summary`,
        method: 'PATCH',
        body: {
          executiveAim: request.executiveAim,
          executiveSummary: request.executiveSummary,
          riskIndicators: request.riskIndicators,
          sentimentAnalysis: request.sentimentAnalysis,
          redFlags: request.redFlags,
          webNewsFindings: request.webNewsFindings,
          digitalBehavior: request.digitalBehavior,
          digitalInfluence: request.digitalInfluence,
          recommendationsImprovements: request.recommendationsImprovements,
          recommentationsConclusion: request.recommentationsConclusion,
          financialMisconduct: request.financialMisconduct || undefined,
          legalDispute: request.legalDispute || undefined,
          criminalRecords: request.criminalRecords || undefined,
          legalJudgments: request.legalJudgments || undefined,
          positiveMentions: request.positiveMentions || undefined,
          assessment: request.assessment || undefined,
          complianceDisclaimer: request.complianceDisclaimer || undefined,
          adverseMediaSummary: request.adverseMediaSummary || undefined,
          recommendationsInsights: request.recommendationsInsights || undefined,
        },
      }),
      invalidatesTags: [RTKTags.subjects],
    }),
    changeReportTopics: builder.mutation<void, changeReportTopicsRequest>({
      query: (request: changeReportTopicsRequest) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/topics`,
        method: 'PATCH',
        body: {
          topics: request.topics,
          topicsIds: request.topicsIds,
        },
      }),
      invalidatesTags: [RTKTags.subjects],
    }),
  }),
})

export const {
  useLazyGetSubjectQuery,
  useUpdateSocialMediaInfoMutation,
  useAddNewSocialMediaMutation,
  useLazyGetSubjectPostsQuery,
  useDeletePostMutation,
  useDeleteSocialMediaMutation,
  useCreateNewPostMutation,
  useModifyPostMutation,
  useCreateNewWebSearchPostMutation,
  useModifyWebSearchPostMutation,
  useModifyPersonDataMutation,
  useModifySummaryMutation,
  useDeleteWebSearchPostMutation,
  useChangeReportTopicsMutation,
} = subjectsApi

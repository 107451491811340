import React from 'react'

import { Chip } from '@/components/Chip/Chip'
import { Card } from '@/components/ui/card'

import { FocusedTopicsSheet } from './FocusedTopicsSheet'

export const DisplayFocusedTopics = ({ data }: { data: string[] }) => {
  return (
    <Card className="flex flex-col gap-3 p-4 bg-baseColorHighlight rounded-xl">
      <p className="text-neutralColor text-xl font-medium">Focused Topics</p>
      <div className="flex items-center gap-6">
        {data.map((topic) => (
          <Chip blue text={topic} isTag />
        ))}
        <FocusedTopicsSheet />
      </div>
    </Card>
  )
}

import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import emptyAvatar from '@/assets/avatarEmptyState.svg'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Switch } from '@/components/ui/switch'
import { Textarea } from '@/components/ui/text-area'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import { useAddNewSocialMediaMutation } from '@/store/api/Subjects/Subjects.query'
import {
  selectPersonData,
  selectSubjectId,
} from '@/store/api/Subjects/Subjects.selector'
import type { addNewSocialMediaRequest } from '@/store/api/Subjects/Subjects.types'
import { setCreatePlatformToken } from '@/store/api/Subjects/SubjectsGuards.slice'
import { filterEmptyFields } from '@/utils/filterEmptyFields'
import type { addSocialMediaSchemaType } from '@/utils/schemas'
import { addSocialMediaSchema } from '@/utils/schemas'

import { useHasCurrentScreenToken } from './Guards/SubjectGuards'

export const NewSocialMedia = () => {
  useHasCurrentScreenToken('createPlatform')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const subjectId = useSelector(selectSubjectId)
  const personData = useSelector(selectPersonData)
  const [selectedPlatform, setSelectedPlatform] = useState<string>()

  const handleProductChange = (value: string) => {
    setSelectedPlatform(value)
  }
  const [createNewSocialMedia, { isLoading }] = useAddNewSocialMediaMutation()

  const handleAddNewSocialMedia = async (data: any) => {
    const postsCount = Number(data.postsCount as string)
    const following = Number(data.following as string)
    const followers = Number(data.followers as string)

    try {
      const requestInfo: addNewSocialMediaRequest = {
        name: selectedPlatform || '',
        userName: data.userName,
        profileLink: data.profileLink,
        profileImgUrl: data.profileImgUrl,
        postsCount: postsCount,
        following: following,
        followers: followers,
        bio: data.bio,
        subjectId: subjectId || '',
        isPrivate: data.isPrivate,
      }
      const filteredRequestInfo = filterEmptyFields(requestInfo, 'subjectId')
      await createNewSocialMedia(filteredRequestInfo).unwrap()
      toast.success('Social Media added successfully.')
      navigate(AppRoutes.socialMedia)
      dispatch(setCreatePlatformToken(false))
    } catch (e) {
      toast.error('Error adding new Social Media.')
      console.error(e)
    }
  }

  const form = useForm<addSocialMediaSchemaType>({
    resolver: zodResolver(addSocialMediaSchema),
    defaultValues: {
      userName: '',
      bio: '',
      profileImgUrl: '',
      profileLink: '',
      followers: '',
      following: '',
      postsCount: '',
      isPrivate: false,
    },
    mode: 'onChange',
  })

  const { handleSubmit, control, setValue, watch, formState } = form
  const isPrivate = watch('isPrivate')
  const profileImgUrl = watch('profileImgUrl')
  const [previewUrl, setPreviewUrl] = useState<string>(emptyAvatar)

  useEffect(() => {
    if (profileImgUrl?.trim()) {
      setPreviewUrl(profileImgUrl)
    } else {
      setPreviewUrl(emptyAvatar)
    }
  }, [profileImgUrl])

  const onSubmit = (data: any) => {
    handleAddNewSocialMedia(data)
  }

  const handleCancel = () => {
    dispatch(setCreatePlatformToken(false))
    navigate(AppRoutes.socialMedia)
  }

  return (
    <WeScreen
      boxCustomPadding={16}
      useNextButton={false}
      isLoading={isLoading}
      rightSideHeaderContent={
        <div className="flex gap-3 items center">
          <Button variant={'secondary'} onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            type="submit"
            form="form"
            onClick={handleSubmit(onSubmit)}
            disabled={!formState.isValid}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form {...form}>
        <form className="flex flex-col gap-8">
          <div className="flex items-center justify-start gap-5">
            <div className="border border-primaryColor30 rounded-full w-12 h-12">
              <div className="flex justify-center items-center h-12">
                <img
                  alt="IMG"
                  src={previewUrl}
                  className="w-full h-full rounded-full"
                />
              </div>
            </div>
            <p className="text-neutralColor text-xl font-medium">
              {personData?.name}
            </p>
          </div>
          <div className="flex flex-col gap-5">
            <p className="text-neutralColor text-base font-medium">
              Profile Data
            </p>
            <div className="flex flex-wrap gap-y-6 justify-between items-end">
              <div className="w-[49.5%] flex flex-col justify-center space-y-2">
                <FormLabel>Platform</FormLabel>
                <Select
                  onValueChange={handleProductChange}
                  value={selectedPlatform}
                >
                  <SelectTrigger className="h-[40px]">
                    <SelectValue placeholder="Select Platform" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectLabel>Platforms</SelectLabel>
                      <SelectItem value={'LinkedIn'}>LinkedIn</SelectItem>
                      <SelectItem value={'Facebook'}>Facebook</SelectItem>
                      <SelectItem value={'Instagram'}>Instagram </SelectItem>
                      <SelectItem value={'Pinterest'}>Pinterest</SelectItem>
                      <SelectItem value={'TikTok'}>TikTok</SelectItem>
                      <SelectItem value={'Twitter'}>Twitter</SelectItem>
                      <SelectItem value={'Reddit'}>Reddit</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <FormField
                control={control}
                name="userName"
                render={({ field }) => (
                  <FormItem className="w-[49.5%]">
                    <FormLabel>User Name</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage data-name="userName" className="absolute" />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="followers"
                render={({ field }) => (
                  <FormItem className="w-[32%]">
                    <FormLabel>Followers</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage data-name="followers" className="absolute" />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="following"
                render={({ field }) => (
                  <FormItem className="w-[32%]">
                    <FormLabel>Following</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage data-name="following" className="absolute" />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="postsCount"
                render={({ field }) => (
                  <FormItem className="w-[32%]">
                    <FormLabel>Posts Count</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage data-name="postsCount" className="absolute" />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="bio"
                render={({ field }) => (
                  <FormItem className="w-[32%]">
                    <FormLabel>Bio</FormLabel>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage data-name="bio" className="absolute" />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="profileLink"
                render={({ field }) => (
                  <FormItem className="w-[32%]">
                    <FormLabel>Profile Link</FormLabel>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage data-name="profileLink" className="absolute" />
                  </FormItem>
                )}
              />
              <FormField
                name="profileImgUrl"
                render={({ field }) => (
                  <FormItem className="w-[32%]">
                    <FormLabel>Profile Img Url</FormLabel>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage
                      data-name="profileImgUrl"
                      className="absolute"
                    />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <p className="text-neutralColor text-base font-medium">Privacy</p>
            <div className="flex justify-between">
              <FormField
                name="isPrivate"
                render={({ field }) => (
                  <FormItem className="w-[49%] h-12 px-3 bg-baseColorHighlight rounded-2xl border border-neutralColor60 flex items-center justify-between">
                    <p className="text-neutral-800 text-base font-medium">
                      Private
                    </p>
                    <FormControl>
                      <Switch
                        {...field}
                        className="w-12 h-6"
                        checked={isPrivate}
                        onCheckedChange={(checked) => {
                          setValue('isPrivate', checked)
                        }}
                      />
                    </FormControl>
                    <FormMessage data-name="isPrivate" />
                  </FormItem>
                )}
              />
              <FormField
                name="isPrivate"
                render={({ field }) => (
                  <FormItem className="w-[49%] h-12 px-3 bg-baseColorHighlight rounded-2xl border border-neutralColor60 flex items-center justify-between">
                    <p className="text-neutral-800 text-base font-medium">
                      Public
                    </p>
                    <FormControl>
                      <Switch
                        {...field}
                        className="w-12 h-6"
                        checked={!isPrivate}
                        onCheckedChange={(checked) => {
                          setValue('isPrivate', !checked)
                        }}
                      />
                    </FormControl>
                    <FormMessage data-name="isPrivate" />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </form>
      </Form>
    </WeScreen>
  )
}

import { createSelector } from '@reduxjs/toolkit'

import type { RootState } from '@/store/store'

const selectSubjectInfo = (state: RootState) => state.subjectInfo.subjectInfo

export const selectPersonData = createSelector(
  [selectSubjectInfo],

  (subjectInfo) => subjectInfo?.personData || null,
)

export const selectSummary = createSelector(
  [selectSubjectInfo],

  (subjectInfo) => subjectInfo?.summary || null,
)

export const selectSocialMedia = createSelector(
  [selectSubjectInfo],

  (subjectInfo) => subjectInfo?.socialMedia || [],
)

export const selectSubjectId = createSelector(
  [selectSubjectInfo],

  (subjectInfo) => subjectInfo?.subjectId || null,
)

export const selectSubjectTopics = createSelector(
  [selectSubjectInfo],

  (subjectInfo) => subjectInfo?.input.topics || [],
)

export const selectNewPostFlags = (state: RootState) =>
  state.subjectInfo.selectedCreatePostFlags

export const selectCurrentPlatform = (state: RootState) =>
  state.subjectInfo.currentPlatform

export const selectEditPostFlags = (state: RootState) =>
  state.subjectInfo.selectedEditPostFlags

export const selectSubjectsGuardsToken = (state: RootState) =>
  state.subjectsGuardsSlice

import type { ParseResult } from 'papaparse'
import Papa from 'papaparse'

import type { ICsvFile } from '@/store/api/Batch/Batch.types'

export const parseCsv = async (csvData: string): Promise<ICsvFile[]> => {
  return new Promise((resolve, reject) => {
    Papa.parse<ICsvFile>(csvData, {
      header: true,
      delimiter: ',',
      skipEmptyLines: true,
      transform: (value: string): string => {
        return value.trim()
      },
      complete: (results: ParseResult<ICsvFile>) => {
        if (results.errors.length > 0) {
          reject(results.errors)
        } else {
          resolve(results.data)
        }
      },
    })
  })
}

export enum AppRoutes {
  dashboard = '/dashboard/companies',
  login = '/',
  test = '',
  topics = '/dashboard/topics',
  newTopic = '/dashboard/new-topic',
  socialMedia = '/dashboard/social-media',
  newSocialMedia = '/dashboard/new-social-media',
  newPost = '/dashboard/new-post',
  modifyPersonalInfo = '/dashboard/edit-subject-information',
  modifySummary = '/dashboard/edit-core-findings',
  enterprises = '/dashboard/companies',
  users = '/dashboard/users',
  reports = '/dashboard/reports',
  batchUpload = '/dashboard/batch-upload',
  platformAndPostsEdition = '/dashboard/platform-and-posts-edition',
  editProfileData = '/dashboard/edit-profile-data',
  editPost = '/dashboard/edit-post',
  scrapeData = '/dashboard/scrape-data',
  feedBackScreen = '/dashboard/feed-back-screen',
  companyUsers = '/dashboard/company-users',
}

import { zodResolver } from '@hookform/resolvers/zod'
import { format } from 'date-fns'
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Chip } from '@/components/Chip/Chip'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/text-area'
import { AppRoutes } from '@/constants/appRoutes'
import {
  useCreateNewPostMutation,
  useCreateNewWebSearchPostMutation,
} from '@/store/api/Subjects/Subjects.query'
import type {
  createNewPostRequest,
  createNewWebSearchPostRequest,
  SocialMedia,
} from '@/store/api/Subjects/Subjects.types'
import { filterEmptyFields } from '@/utils/filterEmptyFields'
import type { createNewPostSchemaType } from '@/utils/schemas'
import { createNewPostSchema } from '@/utils/schemas'

import 'react-datepicker/dist/react-datepicker.css'
import {
  selectCurrentPlatform,
  selectNewPostFlags,
  selectSubjectId,
} from '@/store/api/Subjects/Subjects.selector'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { Switch } from '@/components/ui/switch'

import { LoadFlagSheet } from './components/LoadFlagSheet'

import { clearCreatePostSelectedFlags } from '@/store/api/Subjects/Subjects.slice'

import { useHasCurrentScreenToken } from './Guards/SubjectGuards'

import { setCreatePostToken } from '@/store/api/Subjects/SubjectsGuards.slice'

export const NewPost = () => {
  useHasCurrentScreenToken('createPost')
  const socialMediaData: SocialMedia = useSelector(selectCurrentPlatform)
    ?.platformData
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const subjectId = useSelector(selectSubjectId)
  const selectedNewPostFlags = useSelector(selectNewPostFlags)
  const [selectedType, setSelectedType] = useState<string>(
    socialMediaData.name === 'Web/News Posts' ||
      socialMediaData.name === 'Adverse Media Posts'
      ? 'WebSearch'
      : 'Like',
  )
  const [selectedSentiment, setSelectedSentiment] = useState<string>(
    socialMediaData.name === 'Adverse Media Posts' ? 'Negative' : 'Neutral',
  )
  const [createNewPost, { isLoading }] = useCreateNewPostMutation()
  const [createNewWebSearchPost, { isLoading: isLoadingCreateWebSearchPost }] =
    useCreateNewWebSearchPostMutation()
  const [startDate, setStartDate] = useState(new Date())

  const handleCreateNewPost = async (data: any) => {
    const formattedDate = format(startDate, 'MM-dd-yyyy').toString()
    try {
      const requestInfo: createNewPostRequest = {
        content: data.content,
        link: data.link,
        mediaUrl: data.mediaUrl,
        videoLink: data.videoLink,
        type: selectedType,
        sentimentFlag: selectedSentiment,
        flags: selectedNewPostFlags
          ? selectedNewPostFlags.map((flag: string) => flag.trim())
          : [],
        flaggedText: data.flaggedText,
        subjectId: subjectId || '',
        id: socialMediaData.id,
        flagReason: data.flagReason,
        sentimentReason: data.sentimentReason,
        date: formattedDate,
        platform:
          socialMediaData.name === 'X' ? 'Twitter' : socialMediaData.name,
      }
      const filteredRequestInfo = filterEmptyFields(
        requestInfo,
        'id',
        'subjectId',
      )

      await createNewPost(filteredRequestInfo).unwrap()
      dispatch(clearCreatePostSelectedFlags())
      toast.success('Post created successfully.')
      navigate(AppRoutes.platformAndPostsEdition)
      dispatch(setCreatePostToken(false))
    } catch (e) {
      toast.error('Error creating new Post.')
      console.error(e)
    }
  }

  const handleCreateNewWebSearchPost = async (data: any) => {
    try {
      const requestInfo: createNewWebSearchPostRequest = {
        content: data.content,
        link: data.link,
        mediaUrl: data.mediaUrl,
        videoLink: data.videoLink,
        sentimentFlag: selectedSentiment,
        flags: selectedNewPostFlags
          ? selectedNewPostFlags.map((flag: string) => flag.trim())
          : [],
        flaggedText: data.flaggedText,
        subjectId: subjectId || '',
        flagReason: data.flagReason,
        sentimentReason: data.sentimentReason,
      }
      const filteredRequestInfo = filterEmptyFields(requestInfo)
      await createNewWebSearchPost(filteredRequestInfo).unwrap()

      if (socialMediaData.name === 'Adverse Media Posts') {
        toast.success('Adverse Media Post created successfully.')
      } else {
        toast.success('Web Search Post created successfully.')
      }
      navigate(AppRoutes.platformAndPostsEdition)
      dispatch(setCreatePostToken(false))
    } catch (e) {
      toast.error('Error creating new Web Search Post.')
      console.error(e)
    }
  }

  const form = useForm<createNewPostSchemaType>({
    resolver: zodResolver(createNewPostSchema),
    defaultValues: {
      content: '',
      link: '',
      mediaUrl: '',
      videoLink: '',
      flags: '',
      flaggedText: '',
      sentimentReason: '',
      flagReason: '',
    },
    mode: 'onChange',
  })

  const { handleSubmit, control, formState } = form

  const onSubmit = (data: any) => {
    handleCreateNewPost(data)
  }

  const onSubmitWebSearch = (data: any) => {
    handleCreateNewWebSearchPost(data)
  }

  const handleCancel = () => {
    dispatch(clearCreatePostSelectedFlags())
    navigate(AppRoutes.platformAndPostsEdition)
    dispatch(setCreatePostToken(false))
  }
  return (
    <WeScreen
      boxCustomPadding={16}
      useNextButton={false}
      rightSideHeaderContent={
        <div className="flex gap-3 items center">
          <Button variant={'secondary'} onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            type="submit"
            form="form"
            onClick={
              socialMediaData.name === 'Adverse Media Posts' ||
              socialMediaData.name === 'Web/News Posts'
                ? handleSubmit(onSubmitWebSearch)
                : handleSubmit(onSubmit)
            }
            disabled={!formState.isValid}
            isLoading={isLoadingCreateWebSearchPost || isLoading}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form {...form}>
        <form className="flex flex-col gap-6">
          <Card className="flex flex-col p-4">
            <p className="text-neutralColor text-base font-medium mb-6">
              Post Data
            </p>

            <div className="mb-6">
              <FormItem className="w-full">
                <FormLabel>Type</FormLabel>
                <div className="flex justify-between">
                  <FormField
                    name="type"
                    disabled={
                      socialMediaData.name === 'Adverse Media Posts' ||
                      socialMediaData.name === 'Web/News Posts'
                    }
                    render={({ field }) => (
                      <FormItem className="flex h-12 p-3 rounded-2xl border border-neutralColor30 justify-between items-end w-[24%]">
                        <p className="font-medium">Like</p>
                        <FormControl>
                          <Switch
                            className="w-12 h-6"
                            {...field}
                            checked={selectedType === 'Like'}
                            onCheckedChange={(checked) => {
                              if (checked) setSelectedType('Like')
                            }}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="type"
                    disabled={
                      socialMediaData.name === 'Adverse Media Posts' ||
                      socialMediaData.name === 'Web/News Posts'
                    }
                    render={({ field }) => (
                      <FormItem className="flex h-12 p-3 rounded-2xl border border-neutralColor30 justify-between items-end w-[24%]">
                        <p className="font-medium">Post</p>
                        <FormControl>
                          <Switch
                            className="w-12 h-6"
                            {...field}
                            checked={selectedType === 'Post'}
                            onCheckedChange={(checked) => {
                              if (checked) setSelectedType('Post')
                            }}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="type"
                    disabled={
                      socialMediaData.name === 'Adverse Media Posts' ||
                      socialMediaData.name === 'Web/News Posts'
                    }
                    render={({ field }) => (
                      <FormItem className="flex h-12 p-3 rounded-2xl border border-neutralColor30 justify-between items-end w-[24%]">
                        <p className="font-medium">Repost</p>
                        <FormControl>
                          <Switch
                            className="w-12 h-6"
                            {...field}
                            checked={selectedType === 'Repost'}
                            onCheckedChange={(checked) => {
                              if (checked) setSelectedType('Repost')
                            }}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="type"
                    disabled={
                      socialMediaData.name === 'Adverse Media Posts' ||
                      socialMediaData.name === 'Web/News Posts'
                    }
                    render={({ field }) => (
                      <FormItem className="flex h-12 p-3 rounded-2xl border border-neutralColor30 justify-between items-end w-[24%]">
                        <p className="font-medium">Reply</p>
                        <FormControl>
                          <Switch
                            className="w-12 h-6"
                            {...field}
                            checked={selectedType === 'Reply'}
                            onCheckedChange={(checked) => {
                              if (checked) setSelectedType('Reply')
                            }}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </div>
              </FormItem>
            </div>

            <div className="flex flex-wrap justify-between gap-y-6">
              <div className="flex flex-col w-[49%] gap-2 justify-end">
                <FormLabel>Date</FormLabel>
                <DatePicker
                  disabled={
                    socialMediaData.name === 'Adverse Media Posts' ||
                    socialMediaData.name === 'Web/News Posts'
                  }
                  className="flex h-11 w-full rounded-md border border-neutralColor30 bg-baseColorHighlight px-3 py-1 text-sm shadow-sm focus-visible:outline-none"
                  selected={startDate}
                  onChange={(date: any) => setStartDate(date)}
                />
              </div>
              <FormField
                control={control}
                name="link"
                render={({ field }) => (
                  <FormItem className="w-[49%]">
                    <FormLabel>Link</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage data-name="link" />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="mediaUrl"
                render={({ field }) => (
                  <FormItem className="w-[49%]">
                    <FormLabel>Media Url</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage data-name="mediaUrl" />
                  </FormItem>
                )}
              />
              <FormField
                name="videoLink"
                render={({ field }) => (
                  <FormItem className="w-[49%]">
                    <FormLabel>Video Link</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage data-name="videoLink" />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="content"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Content</FormLabel>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage data-name="Content" />
                  </FormItem>
                )}
              />
            </div>
          </Card>
          <Card className="flex flex-col gap-6 p-4">
            <div className="flex flex-col gap-3">
              <p className="text-neutralColor text-base font-medium">
                Post Sentiment
              </p>
              <p className="text-neutralColor60 text-sm font-medium tracking-tight">
                Select the report section where post will appear.
              </p>
            </div>
            <div>
              <FormItem className="w-full">
                <div className="flex justify-between">
                  <FormField
                    name="sentimentFlag"
                    disabled={socialMediaData.name === 'Web/News Posts'}
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2 h-[94px] p-3 rounded-2xl border border-neutralColor60 justify-start items-between w-[32%]">
                        <div className="flex justify-between items-center">
                          <p className="font-medium text-base text-neutralColor">
                            Negative
                          </p>

                          <FormControl>
                            <Switch
                              className="w-12 h-6"
                              {...field}
                              checked={
                                selectedSentiment === 'Negative' ||
                                socialMediaData.name === 'Adverse Media Posts'
                              }
                              onCheckedChange={(checked) => {
                                if (checked) setSelectedSentiment('Negative')
                              }}
                            />
                          </FormControl>
                        </div>

                        <p className="text-neutralColor60 text-sm font-medium tracking-tight">
                          The post shows negative sentiment or dissatisfaction.
                        </p>
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="sentimentFlag"
                    disabled={socialMediaData.name === 'Adverse Media Posts'}
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2 h-[94px] p-3 rounded-2xl border border-neutralColor60 justify-start items-between w-[32%]">
                        <div className="flex justify-between items-center">
                          <p className="font-medium text-base text-neutralColor">
                            Positive
                          </p>
                          <FormControl>
                            <Switch
                              className="w-12 h-6"
                              {...field}
                              checked={selectedSentiment === 'Positive'}
                              onCheckedChange={(checked) => {
                                if (checked) setSelectedSentiment('Positive')
                              }}
                            />
                          </FormControl>
                        </div>
                        <p className="text-neutralColor60 text-sm font-medium tracking-tight">
                          The post reflects positive sentiment or approval.
                        </p>
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="sentimentFlag"
                    disabled={socialMediaData.name === 'Adverse Media Posts'}
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2 h-[94px] p-3 rounded-2xl border border-neutralColor60 justify-start items-between w-[32%]">
                        <div className="flex justify-between items-center">
                          <p className="font-medium text-base text-neutralColor">
                            Neutral
                          </p>
                          <FormControl>
                            <Switch
                              className="w-12 h-6"
                              {...field}
                              checked={selectedSentiment === 'Neutral'}
                              onCheckedChange={(checked) => {
                                if (checked) setSelectedSentiment('Neutral')
                              }}
                            />
                          </FormControl>
                        </div>
                        <p className="text-neutralColor60 text-sm font-medium tracking-tight">
                          The post has no strong positive or negative tone.
                        </p>
                      </FormItem>
                    )}
                  />
                </div>
              </FormItem>
            </div>
            <div>
              <FormField
                control={control}
                name="sentimentReason"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>
                      {selectedSentiment === ''
                        ? 'Sentiment'
                        : selectedSentiment}
                      &nbsp;Reason
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        disabled={
                          selectedSentiment === '' ||
                          selectedSentiment === 'Positive' ||
                          selectedSentiment === 'Neutral'
                        }
                      />
                    </FormControl>
                    <FormMessage data-name="sentimentReason" />
                  </FormItem>
                )}
              />
            </div>
          </Card>
          <Card className="flex flex-col gap-6 p-4">
            <div className="flex flex-col gap-2">
              <p className="text-neutralColor text-base font-medium">
                Post Flag
              </p>
              <p className="text-neutralColor60 text-sm font-medium tracking-tight">
                Add up to 2 Flags.
              </p>
            </div>
            <div className="flex items-center gap-3">
              <LoadFlagSheet />

              {selectedNewPostFlags?.map((flag, index) => (
                <Chip blue text={flag} key={index} isTag />
              ))}
            </div>

            <FormField
              control={control}
              name="flagReason"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Flag Reason</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage data-name="flagReason" />
                </FormItem>
              )}
            />
          </Card>
        </form>
      </Form>
    </WeScreen>
  )
}

import { differenceInYears } from 'date-fns/differenceInYears'
import * as z from 'zod'

//Regex:

const phoneRegex = /^(.*)$/
const emailRegex = /^(\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+)?$/
const nameRegex = /^[a-z ']*$/i
const addressRegex = /^[\w\d\s.,-]+$/
const passwordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&_*-]).{8,}$/
const cityRegex =
  /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/

const state = /^[A-Za-z ,.'-]*$/
const numbers = /^(\d{1,6})?$/
const numbersWithDecimals = /^(\d{1,6}(\.\d{1,2})?)?$/
const phoneOptional = /^(.*)$/
const organizationName =
  /^[A-Za-z0-9áéíóúüñÑÁÉÍÓÚÜ_.,;:¡!¿?"'@#~$%&*()\-+=\[\]{}<>\s]*$/
const socialMediaRegex =
  /^(https?:\/\/)?(www\.)?(x\.com|tiktok\.com|linkedin\.com|facebook\.com|instagram\.com)?\/?.*$/

const optionalNameRegex = /^(?:[a-zA-Z ']{1,})?$/
const urlRegex =
  /^$|^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,}){1,}(\/\S*)?$/
const hexaColorRegex = /^$|^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
const labelingNameRegex =
  /^$|^[A-Za-z0-9áéíóúüñÑÁÉÍÓÚÜ_.,;:¡!¿?"'@#~$%&*()\-+=\[\]{}<>\s]*$/
const idRegex = /^[A-Za-z0-9]*$/

//ValidationFunctions

const booleanValidation = z
  .boolean()
  .refine((val) => val === true || val === false, {
    message: 'feedback.validations.booleanValidation',
  })

const pdfLanguageValidation = z
  .string()
  .min(1, { message: 'feedback.validations.pdfLanguageValidation' })

const emailValidation = z
  .string()
  .min(1, { message: 'The email field is required' })
  .regex(emailRegex, 'Check the email format')

const idValidation = z
  .string()
  .regex(idRegex, 'The ID must only contain letters and numbers.')

// const numberValidation = z
//  .stringwd()
//  .min(1, { message: 'The number field is required' })
//  .regex(/^\d+$/, 'The number format is invalid')

const passwordValidation = z
  .string()
  .min(8, { message: 'The password must be at least 8 characters long' })
  .max(32, {
    message: 'The password cannot exceed 12 characters.',
  })
  .regex(
    passwordRegex,
    'Invalid format. The password must be include at least one uppercase letter, one digit and a special character like ! or @.',
  )

const nameValidation = z
  .string()
  .min(2, { message: 'Name must be at least 2 characters long' })
  .regex(nameRegex, 'Check the name format')

const subjectValidation = z
  .string()
  .min(2, { message: 'subject must be required' })
  .regex(nameRegex, 'Check the subject format')

const phoneValidation = z
  .string()
  .min(1, { message: 'The phone number is required' })
  .regex(phoneRegex, 'Invalid phone number. Please use a valid format.')

const commentValidation = z
  .string()
  .min(4, { message: 'Comment must be at least 4 characters long' })
  .max(200, {
    message: 'The comment cannot exceed 200 characters.',
  })

const commentOptionalValidation = z
  .string()
  .max(200, {
    message: 'The comment cannot exceed 200 characters.',
  })
  .optional()

const optionalNameValidation = z
  .string()
  .regex(optionalNameRegex, 'Check the name format.')
  .optional()

const firstAndLastNameValidation = z
  .string()
  .min(2, { message: 'Name must be at least 2 characters long.' })
  .max(15, { message: 'Name cannot exceed 15 characters.' })
  .regex(nameRegex, 'Check the name format.')

const companyValidation = z
  .string()
  .min(2, { message: 'Name must be at least 2 characters long.' })
  .max(100, { message: 'Name cannot exceed 15 characters.' })
  .regex(addressRegex, 'Check the Address format.')

const cityValidation = z
  .string()
  .min(3, { message: 'City name must be at least 3 characters long.' })
  .regex(cityRegex, 'Check the city name format.')

const stateValidation = z
  .string()
  .min(1, 'State is required')
  .regex(state, 'Check the state name format')

const newSearchPhone = z.preprocess(
  (a) => {
    if (a) {
      return a
    } else {
      return ''
    }
  },
  z.string().regex(phoneOptional, {
    message: 'Invalid phone number. Please enter a valid phone number.',
  }),
)

const requiredPhone = z
  .string({ description: 'Phone number is required' })
  .min(9)

const backgroundChecksNumber = z
  .string()
  .max(6, 'Number cannot exceed 999,999.')
  .regex(numbers, 'Invalid input. Please enter only numbers.')

const customPrice = z
  .string()
  .max(6, 'Number cannot exceed 999,999.')
  .regex(numbersWithDecimals, 'Invalid input. Please enter only numbers.')

const optionalEmail = z.string().regex(emailRegex, 'Check the email format')

const organizationNameValidation = z
  .string()
  .regex(organizationName, 'Check the name format')
  .min(2, 'Name must be at least 2 characters long')
  .max(60, 'Name cannot exceed 60 characters.')

const urlValidation = z
  .string()
  .regex(urlRegex, 'Invalid URL. Please enter a valid URL.')
  .optional()

const colorValidation = z
  .string()
  .regex(hexaColorRegex, 'Invalid color. Please enter a valid color code.')
  .optional()

const labelingNameValidation = z
  .string()
  .regex(labelingNameRegex, 'Check the name format')
  .max(60, 'Name cannot exceed 60 characters.')
  .optional()

//Schemas

export const loginSchema = z.object({
  email: emailValidation,
  password: passwordValidation,
})

export const contactUsSchema = z.object({
  organizationName: organizationNameValidation,
  name: nameValidation,
  email: emailValidation,
  phoneNumber: newSearchPhone,
  comment: commentValidation,
})

export const BackgroundCheckContactUsSchema = z.object({
  subject: subjectValidation,
  comment: commentValidation,
})

export const signUpSchema = z.object({
  email: emailValidation,
  createPassword: passwordValidation,
  confirmPassword: passwordValidation,
})

export const signUpFirstStepSchema = z.object({
  firstName: firstAndLastNameValidation,
  lastName: firstAndLastNameValidation,
  phoneNumber: phoneValidation,
})

export const signUpSecondStepSchema = z.object({
  companyName: organizationNameValidation,
  companyAddress: companyValidation,
})

export const signUpFinalStepSchema = z.object({
  backgroundChecksNumber: backgroundChecksNumber,
  comment: commentOptionalValidation,
})

export const socialUrlSchema = z
  .string()
  .max(150, 'social media cannot exceed a maximum of 150 characters.')
  .regex(
    socialMediaRegex,
    'Invalid social media url. Please enter a valid URL.',
  )

const isValidDateRange = (date: string) => {
  if (!date || date === '' || (date && date.length < 10)) {
    return true
  }

  const years = differenceInYears(new Date(), new Date(date))

  return years >= 18 && years <= 130
}

const isValidDate = (date: string) => {
  if (!date || date === '' || (date && date.length < 10)) {
    return false
  }
  return true
}

export const dob = z
  .string()
  .refine((data) => isValidDateRange(data), {
    message: 'Invalid age format. Please enter age over 18. ',
  })
  .refine((data) => isValidDate(data), {
    message: 'Incorrect format. Please use MM/DD/YYYY.',
  })

export const newSearchSchema = z.object({
  firstName: firstAndLastNameValidation,
  middleName: optionalNameValidation,
  lastName: firstAndLastNameValidation,
  alias: optionalNameValidation,
  dob: dob,
  addressCity: cityValidation,
  addressCountry: z.optional(cityValidation),
  addressState: z.optional(stateValidation),
  email: z.optional(optionalEmail),
  phoneNumber: newSearchPhone,
  companyName: firstAndLastNameValidation,
  collage: optionalNameValidation,
  socialMediaUrl: socialUrlSchema,
})

export const newQuickSearchSchema = z.object({
  firstName: optionalNameValidation,
  middleName: optionalNameValidation,
  lastName: optionalNameValidation,
  phoneNumber: requiredPhone,
})

export const forgotPasswordSchema = z.object({
  email: emailValidation,
})

export const createPasswordSchema = z.object({
  createPassword: passwordValidation,
  confirmPassword: passwordValidation,
})

export const enterprisesSchema = z.object({
  logoUrl: urlValidation,
  whiteLabelingName: labelingNameValidation,
  backgroundColor: colorValidation,
  highlightColor: colorValidation,
  accentColor: colorValidation,
})

export const creditManagementSchema = z.object({
  weCheckAssisted: z.string().optional(),
  aiAnalystReport: z.string().optional(),
  aiReport: z.string().optional(),
  adverseMedia: z.string().optional(),
  facialMatching: z.string().optional(),
})

export const uploadCsvSchema = z.object({
  ownerId: idValidation,
})

export const createTopicSchema = z.object({
  name: z.string().min(1),
  enterprise: companyValidation.min(1),
  promp: commentValidation.min(1),
})

export const createTopicTranslationSchema = z.object({
  translateName: z.string().min(1),
  description: z.string().min(1),
  keywords: z.string().min(1),
})

export const customPriceSchema = z.object({
  amount: customPrice,
})

export const socialMediaSchema = z.object({
  subjectId: z.string(),
})

export const updateSocialMediaSchema = z.object({
  profileImgUrl: z.string().optional(),
  bio: z.string().optional(),
  followers: z
    .string()
    .regex(numbers, 'Only numbers')
    .min(1, 'Must contain at least 1 number(s).'),
  following: z
    .string()
    .regex(numbers, 'Only numbers')
    .min(1, 'Must contain at least 1 number(s).'),
  postsCount: z
    .string()
    .regex(numbers, 'Only numbers')
    .min(1, 'Must contain at least 1 number(s).'),
  profileLink: z.string(),
  isPrivate: z.boolean(),
  userName: z.string().optional(),
})

export const addSocialMediaSchema = z.object({
  profileImgUrl: z.string().optional(),
  bio: z.string().optional(),
  followers: z
    .string()
    .regex(numbers, 'Only numbers')
    .min(1, 'Must contain at least 1 number(s).'),
  following: z
    .string()
    .regex(numbers, 'Only numbers')
    .min(1, 'Must contain at least 1 number(s).'),
  postsCount: z
    .string()
    .regex(numbers, 'Only numbers')
    .min(1, 'Must contain at least 1 number(s).'),
  profileLink: z.string().min(1),
  userName: z.string().min(1),
  isPrivate: z.boolean(),
})

export const createNewPostSchema = z.object({
  content: z.string().optional(),
  link: z.string().min(1),
  mediaUrl: z.string().optional(),
  videoLink: z.string().optional(),
  flags: z.string().optional(),
  flaggedText: z.string().optional(),
  flagReason: z.string().optional(),
  sentimentReason: z.string().optional(),
})

export const modifyPostSchema = z.object({
  content: z.string().optional(),
  link: z.string().optional(),
  mediaUrl: z.string().optional(),
  videoLink: z.string().optional(),
  flags: z.union([z.string(), z.string().array()]).optional(),
  flaggedText: z.string().nullable().optional(),
  flagReason: z.string().optional(),
  sentimentReason: z.string().optional(),
})

export const modifyPersonDataSchema = z.object({
  name: z.string().optional(),
  nickname: z.string().optional(),
  address: z.string().optional(),
  city: z.string().optional(),
  state: z.union([z.string(), z.string().array()]).optional(),
  country: z.string().optional(),
  email: z.string().optional(),
  highSchool: z.string().optional(),
  college: z.string().optional(),
  company: z.string().optional(),
  phoneNumber: z.string().optional(),
  ageOrDateOfBirth: z.string().optional(),
  profilePicUrl: z.string().optional(),
})

export const modifySummarySchema = z.object({
  executiveAim: z.string().optional(),
  executiveSummary: z.string().optional(),
  riskIndicators: z.string().optional(),
  sentimentAnalysis: z.string().optional(),
  redFlags: z.string().optional(),
  webNewsFindings: z.string().optional(),
  digitalBehavior: z.string().optional(),
  digitalInfluence: z.string().optional(),
  recommendationsImprovements: z.string().optional(),
  recommentationsConclusion: z.string().optional(),
  recommendationsInsights: z.string().optional(),

  financialMisconduct: z.string().optional(),
  legalDispute: z.string().optional(),
  criminalRecords: z.string().optional(),
  legalJudgments: z.string().optional(),
  positiveMentions: z.string().optional(),
  assessment: z.string().optional(),
  complianceDisclaimer: z.string().optional(),
  adverseMediaSummary: z.string().optional(),
})

export const updateTopicSchema = z.object({
  name: z.string().optional(),
  enterprise: z.string().optional(),
  promp: z.string().optional(),
  translations: z
    .array(
      z.object({
        language: z.string().optional(),
        name: z.string().optional(),
        description: z.string().optional(),
        keywords: z.array(z.string()).optional(),
      }),
    )
    .optional(),
})

export const changeReportConfigSchema = z.object({
  pdfCoreFindings: z.boolean().optional(),
  pdfSocialMediaAccounts: z.boolean().optional(),
  pdfSentimentAnalysis: z.boolean().optional(),
  pdfFlaggedBehaviors: z.boolean().optional(),
  pdfPositivePosts: z.boolean().optional(),
  pdfNegativePosts: z.boolean().optional(),
  pdfNeutralPosts: z.boolean().optional(),
  pdfWebSearchPosts: z.boolean().optional(),
  pdfFocusedTopics: z.boolean().optional(),
})

export const reportSettingSchema = z.object({
  coreFindings: booleanValidation,
  flaggedBehaviors: booleanValidation,
  language: pdfLanguageValidation,
  negativePosts: booleanValidation,
  neutralPosts: booleanValidation,
  positivePosts: booleanValidation,
  sentimentAnalysis: booleanValidation,
  socialMediaAccounts: booleanValidation,
  webNewsPosts: booleanValidation,
  pdfFocusedTopics: booleanValidation,
})

export const loadSocialMediaUrlSchema = z.object({
  socialMediaUrl: socialUrlSchema,
})

export const changeTopicsSchema = z.record(z.boolean().optional())

export type LoginType = z.TypeOf<typeof loginSchema>
export type ContactUsType = z.TypeOf<typeof contactUsSchema>
export type BackgroundCheckContactUsType = z.TypeOf<
  typeof BackgroundCheckContactUsSchema
>
export type NewSearchType = z.TypeOf<typeof newSearchSchema>
export type signUpType = z.TypeOf<typeof signUpSchema>
export type signUpFirstStepType = z.TypeOf<typeof signUpFirstStepSchema>
export type signUpSecondStepType = z.TypeOf<typeof signUpSecondStepSchema>
export type signUpFinalStepType = z.TypeOf<typeof signUpFinalStepSchema>
export type forgotPasswordType = z.TypeOf<typeof forgotPasswordSchema>
export type createPasswordType = z.TypeOf<typeof createPasswordSchema>
export type enterprisesSchemaType = z.TypeOf<typeof enterprisesSchema>
export type uploadCsvSchemaType = z.TypeOf<typeof uploadCsvSchema>
export type createTopicSchemaType = z.TypeOf<typeof createTopicSchema>
export type customPriceSchemaType = z.TypeOf<typeof customPriceSchema>
export type socialMediaSchemaType = z.TypeOf<typeof socialMediaSchema>
export type updateSocialMediaSchemaType = z.TypeOf<
  typeof updateSocialMediaSchema
>
export type addSocialMediaSchemaType = z.TypeOf<typeof addSocialMediaSchema>
export type createNewPostSchemaType = z.TypeOf<typeof createNewPostSchema>
export type modifyPostSchemaType = z.TypeOf<typeof modifyPostSchema>
export type modifyPersonDataSchemaType = z.TypeOf<typeof modifyPersonDataSchema>
export type modifySummarySchemaType = z.TypeOf<typeof modifySummarySchema>
export type creditManagementSchemaType = z.TypeOf<typeof creditManagementSchema>
export type createTopicTraslationSchemaType = z.TypeOf<
  typeof createTopicTranslationSchema
>
export type updateTopicSchemaType = z.TypeOf<typeof updateTopicSchema>
export type changeReportConfigSchemaType = z.TypeOf<
  typeof changeReportConfigSchema
>
export type reportSettingSchemaType = z.TypeOf<typeof reportSettingSchema>
export type loadSocialMediaUrlSchemaType = z.TypeOf<
  typeof loadSocialMediaUrlSchema
>
export type changeTopicsSchemaType = z.TypeOf<typeof changeTopicsSchema>

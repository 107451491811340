import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { useForm, useFormState } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Textarea } from '@/components/ui/text-area'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import { useModifySummaryMutation } from '@/store/api/Subjects/Subjects.query'
import {
  selectSubjectId,
  selectSummary,
} from '@/store/api/Subjects/Subjects.selector'
import type { modifySummaryRequest } from '@/store/api/Subjects/Subjects.types'
import { setEditCoreFindingsToken } from '@/store/api/Subjects/SubjectsGuards.slice'
import { filterEmptyFields } from '@/utils/filterEmptyFields'
import { modifySummarySchema } from '@/utils/schemas'
import type { modifySummarySchemaType } from '@/utils/schemas'

import { useHasCurrentScreenToken } from './Guards/SubjectGuards'

export const EditCoreFindings = () => {
  useHasCurrentScreenToken('editCoreFindings')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const subjectId = useSelector(selectSubjectId)
  const currentSummary = useSelector(selectSummary)
  const [modifySummary, { isLoading }] = useModifySummaryMutation()

  const form = useForm<modifySummarySchemaType>({
    resolver: zodResolver(modifySummarySchema),
    defaultValues: {
      executiveAim: currentSummary?.executiveAim || '',
      executiveSummary: currentSummary?.executiveSummary || '',
      riskIndicators: currentSummary?.riskIndicators || '',
      sentimentAnalysis: currentSummary?.sentimentAnalysis || '',
      redFlags: currentSummary?.redFlags || '',
      webNewsFindings: currentSummary?.webNewsFindings || '',
      digitalBehavior: currentSummary?.digitalBehavior || '',
      digitalInfluence: currentSummary?.digitalInfluence || '',
      recommendationsImprovements:
        currentSummary?.recommendationsImprovements || '',
      recommentationsConclusion:
        currentSummary?.recommentationsConclusion || '',
      financialMisconduct: currentSummary?.financialMisconduct || '',
      legalDispute: currentSummary?.legalDispute || '',
      criminalRecords: currentSummary?.criminalRecords || '',
      legalJudgments: currentSummary?.legalJudgments || '',
      positiveMentions: currentSummary?.positiveMentions || '',
      assessment: currentSummary?.assessment || '',
      complianceDisclaimer: currentSummary?.complianceDisclaimer || '',
      adverseMediaSummary: currentSummary?.adverseMediaSummary || '',
      recommendationsInsights: currentSummary?.recommendationsInsights || '',
    },
    mode: 'onChange',
  })

  const { handleSubmit, control } = form
  const { isValid } = useFormState({ control })

  const handleModifySummary = async (data: any) => {
    try {
      const requestInfo: modifySummaryRequest = {
        subjectId: subjectId || '',
        executiveAim: data.executiveAim || undefined,
        sentimentAnalysis: data.sentimentAnalysis || undefined,
        redFlags: data.redFlags || undefined,
        webNewsFindings: data.webNewsFindings || undefined,
        executiveSummary: data.executiveSummary || undefined,
        digitalBehavior: data.digitalBehavior || undefined,
        digitalInfluence: data.digitalInfluence || undefined,
        recommendationsImprovements:
          data.recommendationsImprovements || undefined,
        recommentationsConclusion: data.recommentationsConclusion || undefined,
        financialMisconduct: data.financialMisconduct || undefined,
        legalDispute: data.legalDispute || undefined,
        criminalRecords: data.criminalRecords || undefined,
        legalJudgments: data.legalJudgments || undefined,
        positiveMentions: data.positiveMentions || undefined,
        assessment: data.assessment || undefined,
        complianceDisclaimer: data.complianceDisclaimer || undefined,
        adverseMediaSummary: data.adverseMediaSummary || undefined,
        riskIndicators: data.riskIndicators,
        recommendationsInsights: data.recommendationsInsights,
      }
      const filteredRequestInfo = filterEmptyFields(requestInfo, 'subjectId')
      await modifySummary(filteredRequestInfo).unwrap()
      toast.success('Summary modified successfully.')
      dispatch(setEditCoreFindingsToken(false))
      navigate(AppRoutes.socialMedia)
    } catch (e) {
      toast.error('Error modifying Summary.')
      console.error(e)
    }
  }

  const onSubmit = (data: any) => {
    handleModifySummary(data)
  }

  const handleCancel = () => {
    dispatch(setEditCoreFindingsToken(false))
    navigate(AppRoutes.socialMedia)
  }

  return (
    <WeScreen
      isLoading={isLoading}
      useNextButton={false}
      boxCustomPadding={24}
      containerCustomGap={0}
      rightSideHeaderContent={
        <div className="flex gap-3 items center">
          <Button variant={'secondary'} onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            type="submit"
            form="form"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form {...form}>
        <form className="flex flex-wrap justify-between gap-y-6">
          <FormField
            control={control}
            name="executiveAim"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Executive Aim
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormMessage data-name="executiveAim" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="executiveSummary"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Executive Summary
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormMessage data-name="executiveSummary" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="riskIndicators"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Risk Indicators
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormMessage data-name="riskIndicators" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="sentimentAnalysis"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Sentiment Analysis
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormMessage data-name="sentimentAnalysis" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="redFlags"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Red Flags
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormMessage data-name="redFlags" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="webNewsFindings"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Web News Findings
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormMessage data-name="webNewsFindings" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="digitalBehavior"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Digital Behavior
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormMessage data-name="digitalBehavior" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="digitalInfluence"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Digital Influence
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormMessage data-name="digitalInfluence" />
              </FormItem>
            )}
          />{' '}
          <FormField
            control={control}
            name="recommendationsImprovements"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Recommendations Improvements
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormMessage data-name="recommendationsImprovements" />
              </FormItem>
            )}
          />{' '}
          <FormField
            control={control}
            name="recommentationsConclusion"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Recommentations Conclusion
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormMessage data-name="recommentationsConclusion" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="recommendationsInsights"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Recommendations Insights
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormMessage data-name="recommendationsInsights" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="financialMisconduct"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Financial Misconduct (Only on Adverse Media Report)
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormMessage data-name="financialMisconduct" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="legalDispute"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Legal Dispute (Only on Adverse Media Report)
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormMessage data-name="legalDispute" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="criminalRecords"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Criminal Records (Only on Adverse Media Report)
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormMessage data-name="criminalRecords" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="legalJudgments"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Legal Judgments (Only on Adverse Media Report)
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormMessage data-name="legalJudgments" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="positiveMentions"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Positive Mentions (Only on Adverse Media Report)
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormMessage data-name="positiveMentions" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="assessment"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Assessment (Only on Adverse Media Report)
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormMessage data-name="assessment" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="complianceDisclaimer"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Compliance Disclaimer (Only on Adverse Media Report)
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormMessage data-name="complianceDisclaimer" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="adverseMediaSummary"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Adverse Media Summary (Only on Adverse Media Report)
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormMessage data-name="adverseMediaSummary" />
              </FormItem>
            )}
          />
        </form>
      </Form>
    </WeScreen>
  )
}

import { createSelector } from '@reduxjs/toolkit'

import type { RootState } from '../../store'

import type { AuthSliceState } from './Auth.slice'

export const selectAuth = (state: RootState) => state.auth

export const selectToken = createSelector(
  selectAuth,
  (authState: AuthSliceState) => authState.token,
)
export const selectHasToken = createSelector(
  selectAuth,
  (authState: AuthSliceState) => authState.token !== undefined,
)

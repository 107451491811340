import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import emptyAvatar from '@/assets/emptyAvatar.png'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { selectReportInfo } from '@/store/api/Admin/Admin.selector'
import { useLazyGetSubjectQuery } from '@/store/api/Subjects/Subjects.query'
import { setSubjectInfo } from '@/store/api/Subjects/Subjects.slice'

import { ReportDetailsHeader } from '../Subject/components/ReportDetailsHeader'

import { FoundProfilesCard } from './components/FoundProfilesCard'
import ProfileUploader from './components/ProfileUpload'

export const ScrapeFlow = () => {
  const reportInfo = useSelector(selectReportInfo).reportInfo
  const dispatch = useDispatch()

  const [getSubjectData, { data, isLoading: isLoadingGetSubjectData }] =
    useLazyGetSubjectQuery()

  useEffect(() => {
    if (reportInfo?.subjectId) {
      getSubjectData({ subjectId: reportInfo.subjectId }).then((response) => {
        if (response?.data?.info) {
          dispatch(setSubjectInfo(response.data.info))
        }
      })
    }
  }, [reportInfo, dispatch, getSubjectData])

  return (
    <WeScreen
      isLoading={isLoadingGetSubjectData}
      boxCustomGap={16}
      boxCustomPadding={16}
      useNextButton={false}
    >
      {data && reportInfo && (
        <ReportDetailsHeader
          fullName={data?.info.personData.name || 'Nameless'}
          profileImage={data?.info.personData.profilePicUrl || emptyAvatar}
          id={data?.info.id}
          subjectId={reportInfo.subjectId}
          wecheckScraped={true}
        />
      )}
      <FoundProfilesCard subjectId={reportInfo.subjectId} />
      <ProfileUploader
        subjectId={reportInfo.subjectId}
        personData={data?.info.personData}
      />
    </WeScreen>
  )
}

import { Add } from 'iconsax-react'
import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Facebook from '@/assets/socialMedia/Facebook.svg?react'
import Instagram from '@/assets/socialMedia/Instagram.svg?react'
import LinkedIn from '@/assets/socialMedia/LinkedIn.svg?react'
import Pinterest from '@/assets/socialMedia/Pinterest.svg?react'
import Reddit from '@/assets/socialMedia/Reddit.svg?react'
import TikTok from '@/assets/socialMedia/TikTok.svg?react'
import X from '@/assets/socialMedia/X.svg?react'
import AdverseMediaIcon from '@/assets/socialMediaLogos/adverseMedia.svg?react'
import WebsearchIcon from '@/assets/socialMediaLogos/websearch.svg?react'
import { Button } from '@/components/ui/button'
import { Card, CardTitle } from '@/components/ui/card'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/ui/hover-card'
import { AppRoutes } from '@/constants/appRoutes'
import {
  clearCurrentPlatform,
  setCurrentPlatform,
} from '@/store/api/Subjects/Subjects.slice'
import type { SocialMedia } from '@/store/api/Subjects/Subjects.types'
import {
  setCreatePlatformToken,
  setPlatformAndPostsEditionToken,
} from '@/store/api/Subjects/SubjectsGuards.slice'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

const DEFAULT_PLATFORMS: SocialMedia[] = [
  {
    id: 'default-1',
    name: 'WebSearch',
    profileImgUrl: '',
    userName: '',
    profileLink: '',
    followers: 0,
    following: 0,
    postsCount: 0,
    flaggedCount: 0,
    bio: '',
    isPrivate: false,
  },
  {
    id: 'default-2',
    name: 'Adverse Media Posts',
    profileImgUrl: '',
    userName: '',
    profileLink: '',
    followers: 0,
    following: 0,
    postsCount: 0,
    flaggedCount: 0,
    bio: '',
    isPrivate: false,
  },
]

export const DisplayPlatforms = ({
  socialMediaData,
}: {
  socialMediaData: SocialMedia[]
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [socialMediaTypes] = useState<SocialMedia[]>([
    ...DEFAULT_PLATFORMS.map((media) =>
      media.name === 'WebSearch' ? { ...media, name: 'Web/News Posts' } : media,
    ),
    ...socialMediaData.map((media) =>
      media.name.toLowerCase() === 'twitter' ? { ...media, name: 'X' } : media,
    ),
  ])

  const socialMediaIcons = useMemo(() => {
    const components: JSX.Element[] = []

    const Wrapper = ({ children }: { children: React.ReactNode }) => (
      <div className="rounded-full bg-buttonPrincipalBackground">
        {children}
      </div>
    )

    const navigatePlatformAndPostsEdition = (media: any) => {
      clearCurrentPlatform()
      dispatch(
        setCurrentPlatform({
          platform:
            media.name === 'Web/News Posts' ||
            media.name === 'Adverse Media Posts'
              ? 'WebSearch'
              : media.name === 'X'
                ? 'Twitter'
                : media.name,
          platformData: media,
        }),
      )
      dispatch(setPlatformAndPostsEditionToken(true))
      navigate(AppRoutes.platformAndPostsEdition)
    }

    const iconMapping: { [key: string]: React.ElementType } = {
      Facebook,
      Instagram,
      LinkedIn,
      X,
      TikTok,
      Pinterest,
      Reddit,
      'Web/News Posts': WebsearchIcon,
      'Adverse Media Posts': AdverseMediaIcon,
    }

    socialMediaTypes.forEach((media) => {
      const Icon = iconMapping[media.name]

      if (Icon) {
        components.push(
          <div
            className="flex flex-row gap-3 items-center justify-center"
            key={media.id}
          >
            <HoverCard>
              <HoverCardTrigger>
                <div
                  className="flex items-center"
                  onClick={() => navigatePlatformAndPostsEdition(media)}
                >
                  <Wrapper>
                    <div className="flex justify-center items-center gap-3 h-12 bg-primaryColor5 rounded-[240px] border border-primaryColor30 p-4">
                      <Icon color={weCheckPalette.icon} />
                      <span>{media.name}</span>{' '}
                    </div>
                  </Wrapper>
                </div>
              </HoverCardTrigger>
              <HoverCardContent className="md:w-96">
                <div className="flex flex-col gap-3">
                  <div className="flex justify-center items-center gap-3 h-12 bg-primaryColor5 rounded-[240px] border border-primaryColor30 p-4">
                    <Icon color={weCheckPalette.icon} />
                    <p>{media.name}</p>
                  </div>
                  <p>{media.profileLink}</p>
                </div>
              </HoverCardContent>
            </HoverCard>
          </div>,
        )
      }
    })

    return components
  }, [socialMediaTypes, navigate])

  const navigateNewSocialMedia = () => {
    dispatch(setCreatePlatformToken(true))
    navigate(AppRoutes.newSocialMedia)
  }

  return (
    <Card className="flex flex-col items-start p-4 gap-3">
      <CardTitle className="text-neutralColor text-xl font-medium">
        Platforms
      </CardTitle>
      <div className="flex items-center gap-4">
        <div className="flex gap-4">{socialMediaIcons}</div>
        <Button
          className="rounded-full h-12 w-12 shadow-none bg-transparent border border-neutralColor border-dashed hover:bg-primaryColor5"
          size={'icon'}
          onClick={navigateNewSocialMedia}
        >
          <Add color={weCheckPalette.neutralColor} />
        </Button>
      </div>
    </Card>
  )
}

import { useCallback, useEffect, useState } from 'react'

import { WeScreen } from '@/components/WeScreen/WeScreen'
import { useAppSelector } from '@/hooks/redux'
import { useRedirectBasedOnPermissions } from '@/hooks/useRedirectBasedOnPermissions'
import { useLazyReportsQuery } from '@/store/api/Admin/Admin.query'
import type { ReportsStatus } from '@/store/api/Admin/Admin.types'
import { selectItsDefaultState } from '@/store/api/Users/Users.selector'

import { ReportsTable } from './components/ReportsTable'

export const Reports = () => {
  const [selectedFilter, setSelectedFilter] = useState<ReportsStatus>('All')
  const { hasHandleReports } = useRedirectBasedOnPermissions()
  const itsDefaultState = useAppSelector(selectItsDefaultState)

  useEffect(() => {
    if (!itsDefaultState) hasHandleReports()
  }, [itsDefaultState])

  const [getReports, { data, isLoading, isError, isFetching }] =
    useLazyReportsQuery()

  const handleFilterChange = (value: ReportsStatus) => {
    setSelectedFilter(value)
  }

  const makeGetReportsRequest = useCallback(
    (selectedFilter: ReportsStatus) => {
      getReports({
        status: selectedFilter,
      })
    },
    [selectedFilter],
  )

  useEffect(() => {
    makeGetReportsRequest(selectedFilter)
  }, [selectedFilter])

  return (
    <WeScreen
      title="Reports"
      useHeaderBackButton={false}
      useNextButton={false}
      isLoading={isLoading}
      isError={isError}
    >
      <ReportsTable
        data={data || []}
        selectedFilter={selectedFilter}
        handleFilterChange={handleFilterChange}
        isLoading={isFetching}
      />
    </WeScreen>
  )
}

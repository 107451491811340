export const additionalFlags = [
  'Threats',
  'Suggestive',
  'Nudity Image',
  'Disparaging',
  'Drug Image',
  'Gory Image',
  'Politics/Government',
  'Prejudice',
  'Profanity',
  'Suicide Mentions',
  'Gambling',
  'Weapon Image',
  'Drug/Alcohol Mention',
  'Self Harm',
]

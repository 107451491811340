import { zodResolver } from '@hookform/resolvers/zod'
import { Edit2 } from 'iconsax-react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import emptyAvatar from '@/assets/avatarEmptyState.svg'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import { useModifyPersonDataMutation } from '@/store/api/Subjects/Subjects.query'
import {
  selectPersonData,
  selectSubjectId,
} from '@/store/api/Subjects/Subjects.selector'
import { setEditSubjectInfoToken } from '@/store/api/Subjects/SubjectsGuards.slice'
import { filterEmptyFields } from '@/utils/filterEmptyFields'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import {
  modifyPersonDataSchema,
  type modifyPersonDataSchemaType,
} from '@/utils/schemas'

import { useHasCurrentScreenToken } from './Guards/SubjectGuards'

export const EditSubjectInformation = () => {
  useHasCurrentScreenToken('editSubjectInfo')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const subjectId = useSelector(selectSubjectId)
  const [modifyPersonData, { isLoading }] = useModifyPersonDataMutation()
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const currentPersonData = useSelector(selectPersonData)
  const form = useForm<modifyPersonDataSchemaType>({
    resolver: zodResolver(modifyPersonDataSchema),
    defaultValues: {
      name: currentPersonData?.name || '',
      nickname: currentPersonData?.nickname || '',
      address: currentPersonData?.address || '',
      city: currentPersonData?.city || '',
      state: currentPersonData?.state || '',
      country: currentPersonData?.country || '',
      email: currentPersonData?.email || '',
      highSchool: currentPersonData?.highSchool || '',
      college: currentPersonData?.college || '',
      company: currentPersonData?.company || '',
      phoneNumber: currentPersonData?.phoneNumber || '',
      ageOrDateOfBirth: currentPersonData?.ageOrDateOfBirth || '',
    },
    mode: 'onChange',
  })
  const [previewUrl, setPreviewUrl] = useState<string>(
    currentPersonData?.profilePicUrl
      ? currentPersonData?.profilePicUrl
      : emptyAvatar,
  )

  const { handleSubmit, control } = form

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null
    setSelectedFile(file)
    if (file) {
      const objectUrl = URL.createObjectURL(file)
      setPreviewUrl(objectUrl)
      return () => URL.revokeObjectURL(objectUrl)
    } else {
      setPreviewUrl(emptyAvatar)
    }
  }

  const handleModifyPersonData = async (data: any) => {
    const formData = new FormData()
    if (selectedFile) {
      formData.append('image', selectedFile)
    }

    const dataObject: any = {
      name: data.name || '',
      nickname: data.nickname || '',
      address: data.address || '',
      city: data.city || '',
      state: data.state || '',
      country: data.country || '',
      email: data.email || '',
      college: data.college || '',
      highSchool: data.highSchool || '',
      company: data.company || '',
      phoneNumber: data.phoneNumber || '',
      ageOrDateOfBirth: data.ageOrDateOfBirth || '',
    }
    const filteredDataObject = filterEmptyFields(dataObject)

    Object.keys(filteredDataObject).forEach((key) => {
      formData.append(key, filteredDataObject[key])
    })

    try {
      await modifyPersonData({ formData, subjectId: subjectId || '' }).unwrap()
      toast.success('Person Data modified successfully.')
      navigate(AppRoutes.socialMedia)
      dispatch(setEditSubjectInfoToken(false))
    } catch (e) {
      toast.error('Error modifying Person Data.')
      console.error(e)
    }
  }

  const onSubmit = (data: any) => {
    handleModifyPersonData(data)
  }

  const handleCancel = () => {
    navigate(AppRoutes.socialMedia)
    dispatch(setEditSubjectInfoToken(false))
  }

  return (
    <WeScreen
      boxCustomPadding={16}
      useNextButton={false}
      isLoading={isLoading}
      onClickBack={handleCancel}
      rightSideHeaderContent={
        <div className="flex gap-3 items center">
          <Button variant={'secondary'} onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="submit" form="form" onClick={handleSubmit(onSubmit)}>
            Save
          </Button>
        </div>
      }
    >
      <Form {...form}>
        <form className="flex flex-col gap-8">
          <div className="flex items-center justify-start gap-5">
            <div className="border border-primaryColor30 rounded-full w-12 h-12 ">
              <Input
                className="absolute w-12 h-12 rounded-full"
                style={{ opacity: 0 }}
                accept="image"
                type="file"
                onChange={handleFileUpload}
              />
              <div className="flex justify-center items-center h-12">
                <img
                  alt="IMG"
                  src={previewUrl}
                  className="w-full h-full rounded-full"
                />
                <div className="absolute transform translate-x-4 translate-y-4 bg-white rounded-full p-1">
                  <Edit2
                    size="16"
                    color={weCheckPalette.neutralColor}
                    variant="Bold"
                  />
                </div>
              </div>
            </div>
            <p className="text-neutralColor text-xl font-medium">
              {currentPersonData?.name}
            </p>
          </div>

          <div className="flex flex-col gap-5">
            <p className="text-neutralColor text-base font-medium">
              Personal Information
            </p>
            <div className="flex flex-wrap gap-y-6 justify-between">
              <FormField
                control={control}
                name="name"
                render={({ field }) => (
                  <FormItem className="w-[49%]">
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="nickname"
                render={({ field }) => (
                  <FormItem className="w-[49%]">
                    <FormLabel>Alias</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage data-name="nickname" />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="email"
                render={({ field }) => (
                  <FormItem className="w-[32.5%]">
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage data-name="email" />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="phoneNumber"
                render={({ field }) => (
                  <FormItem className="w-[32.5%]">
                    <FormLabel>Phone Number</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage data-name="phoneNumber" />
                  </FormItem>
                )}
              />{' '}
              <FormField
                control={control}
                name="ageOrDateOfBirth"
                render={({ field }) => (
                  <FormItem className="w-[32.5%]">
                    <FormLabel>Age Approx.</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage data-name="ageOrDateOfBirth" />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <p className="text-neutralColor text-base font-medium">
              Professional Information
            </p>
            <div className="flex justify-between">
              <FormField
                control={control}
                name="company"
                render={({ field }) => (
                  <FormItem className="w-[49%]">
                    <FormLabel>Company</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage data-name="company" />
                  </FormItem>
                )}
              />{' '}
              <FormField
                control={control}
                name="college"
                render={({ field }) => (
                  <FormItem className="w-[49%]">
                    <FormLabel>College</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage data-name="college" />
                  </FormItem>
                )}
              />{' '}
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <p className="text-neutralColor text-base font-medium">Location</p>
            <div className="flex justify-between">
              <FormField
                control={control}
                name="country"
                render={({ field }) => (
                  <FormItem className="w-[32.5%]">
                    <FormLabel>Country</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage data-name="country" />
                  </FormItem>
                )}
              />
              <FormField
                name="state"
                render={({ field }) => (
                  <FormItem className="w-[32.5%]">
                    <FormLabel>State</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage data-name="state" />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="city"
                render={({ field }) => (
                  <FormItem className="w-[32.5%]">
                    <FormLabel>City</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage data-name="city" />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </form>
      </Form>
    </WeScreen>
  )
}

import { api, RTKTags } from '@/store/api'

import Env from '../../../env/env'

import type {
  BECrudResponse,
  BackgroundCheckPdfRequest,
  BackgroundCheckPdfResponse,
  ReportsRequest,
  ReportsResponse,
  uploadPdfRequest,
  uploadPdfResponse,
  UserResponse,
  UserRolAndState,
  Usercrudresponse,
  ConfigurationCrudResponse,
  UpdateReportValueRequest,
  ConfigurationItem,
  regenerateReportRequest,
  changeReportPdfConfigurationRequest,
} from './Admin.types'

export const reportsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    reports: builder.query<ReportsResponse, ReportsRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/dashboard/social-checks`,
        method: 'GET',
        params: {
          status: request.status,
        },
      }),
      providesTags: [RTKTags.reports],

      transformResponse: (response: BECrudResponse<ReportsResponse>) => {
        return response.info
      },
    }),
    uploadPdf: builder.mutation<uploadPdfResponse, uploadPdfRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/dashboard/reports/${request.socialCheckId}/upload`,
        method: 'POST',
        body: request.file,
      }),
      invalidatesTags: [RTKTags.reports, RTKTags.subjects],
    }),
    backgroundCheckPdf: builder.mutation<
      BackgroundCheckPdfResponse,
      BackgroundCheckPdfRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/socials/re-download-pdf/${request.id}`,
        method: 'GET',
      }),
    }),
    users: builder.query<UserResponse, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/users`,
        method: 'GET',
      }),
      transformResponse: (response: Usercrudresponse) => {
        return response.info
      },
      keepUnusedDataFor: 0,
      providesTags: [RTKTags.users],
    }),
    usersRolAndState: builder.mutation<void, UserRolAndState>({
      query: (request: UserRolAndState) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/users/${request.id}`,
        method: 'PATCH',
        body: {
          status: request.status,
          role: request.role,
        },
      }),
      invalidatesTags: [RTKTags.users],
    }),
    configuration: builder.query<ConfigurationItem, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/configurations`,
        method: 'GET',
      }),
      transformResponse: (response: ConfigurationCrudResponse) => {
        return response.info
      },
      keepUnusedDataFor: 0,
      providesTags: [RTKTags.users],
    }),
    updateReportValue: builder.mutation<void, UpdateReportValueRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/dashboard/reports/${request.socialCheckId}`,
        method: 'PUT',
        body: {
          status: request.status,
        },
      }),
      invalidatesTags: [RTKTags.reports],
    }),
    regenerateReport: builder.mutation<void, regenerateReportRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/pdf/re-build/${request.id}`,
        method: 'GET',
        timeout: 9000000,
      }),
      invalidatesTags: [RTKTags.reports, RTKTags.subjects],
    }),
    regenerateWithoutIA: builder.mutation<void, regenerateReportRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/pdf/regenerates/${request.id}`,
        method: 'GET',
        timeout: 9000000,
      }),
      invalidatesTags: [RTKTags.reports, RTKTags.subjects],
    }),
    regenerateWithSummary: builder.mutation<void, regenerateReportRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/pdf/regeneratesWithSummary/${request.id}`,
        method: 'GET',
        timeout: 9000000,
      }),
      invalidatesTags: [RTKTags.reports, RTKTags.subjects],
    }),
    reAnalyzeAndGeneratePdf: builder.mutation<void, regenerateReportRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/pdf/reAnalyzeAndGeneratePdf/${request.id}`,
        method: 'GET',
        timeout: 9000000,
      }),
      invalidatesTags: [RTKTags.reports, RTKTags.subjects],
    }),
    changeReportPdfConfiguration: builder.mutation<
      void,
      changeReportPdfConfigurationRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/pdf-config`,
        method: 'PATCH',
        body: {
          pdfLanguage: request.pdfLanguage,
          pdfCoreFindings: request.pdfCoreFindings,
          pdfSocialMediaAccounts: request.pdfSocialMediaAccounts,
          pdfSentimentAnalysis: request.pdfSentimentAnalysis,
          pdfFlaggedBehaviors: request.pdfFlaggedBehaviors,
          pdfPositivePosts: request.pdfPositivePosts,
          pdfNegativePosts: request.pdfNegativePosts,
          pdfNeutralPosts: request.pdfNeutralPosts,
          pdfWebSearchPosts: request.pdfWebSearchPosts,
          pdfFocusedTopics: request.pdfFocusedTopics,
        },
      }),
      invalidatesTags: [RTKTags.reports],
    }),
  }),
})
export const {
  useLazyReportsQuery,
  useUploadPdfMutation,
  useBackgroundCheckPdfMutation,
  useUsersQuery,
  useUsersRolAndStateMutation,
  useConfigurationQuery,
  useUpdateReportValueMutation,
  useRegenerateReportMutation,
  useRegenerateWithoutIAMutation,
  useRegenerateWithSummaryMutation,
  useReAnalyzeAndGeneratePdfMutation,
  useChangeReportPdfConfigurationMutation,
} = reportsApi

import type {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
} from '@tanstack/react-table'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { ArrowRight2 } from 'iconsax-react'
import { isEmpty } from 'ramda'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { EmptyState } from '@/components/EmptyState/EmptyState'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { AppRoutes } from '@/constants/appRoutes'
import { setEnterprise } from '@/store/api/Enterprises/Enterprises.slice'
import type { Enterprise } from '@/store/api/Enterprises/Enterprises.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

export function CompaniesTable({ data }: { data: Enterprise[] }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [sorting, setSorting] = React.useState<SortingState>([])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  )
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = React.useState({})
  const [globalFilter, setGlobalFilter] = React.useState('')

  const handlePickEnterprise = (enterprise: Enterprise) => {
    dispatch(setEnterprise({ ...enterprise }))
    navigate(AppRoutes.companyUsers)
  }

  const columns: ColumnDef<any>[] = [
    {
      accessorKey: 'name',
      header: () => 'Name',
      cell: ({ row }) => row.getValue('name') || 'Nameless',
    },
    {
      accessorKey: 'emailOwner',
      header: () => 'Owner Email',
      cell: ({ row }) => row.getValue('emailOwner') || 'No Email',
    },
    {
      accessorKey: 'address',
      header: () => 'Location',
      cell: ({ row }) => row.getValue('address') || 'No Location',
    },

    {
      id: 'Actions',
      cell: ({ row }) => (
        <div className="flex justify-end">
          <ArrowRight2
            className="w-5 h-5 mr-2"
            color={weCheckPalette.neutralColor}
            onClick={() => handlePickEnterprise(row.original)}
          />
        </div>
      ),
    },
  ]

  const table = useReactTable({
    data: data ?? [],
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      globalFilter,
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  if (isEmpty(data)) {
    return (
      <EmptyState text="We will show here to available companies when there are any" />
    )
  }

  return (
    <div className="flex flex-col w-full p-4 max-xl:gap-4 gap-6">
      <div className="flex py-4 mb-2">
        <Input
          placeholder="Filter by any element..."
          value={globalFilter}
          onChange={(event) => {
            const value = event.target.value
            setGlobalFilter(value)
          }}
          className="h-10 border border-neutralColor30 rounded-lg w-[376px]"
        />
      </div>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    className="text-sm font-medium tracking-tighter"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <EmptyState text="There seem to be results for your search" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="space-x-2">
          <Button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="h-10 px-4 py-2 rounded-lg border border-primary30 bg-primaryColor5"
          >
            <p className="text-neutralColor text-base font-normal">Previous</p>
          </Button>
          <Button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="h-10 px-4 py-2 rounded-lg border border-primary30 bg-primaryColor5"
          >
            <p className="text-neutralColor text-base font-normal">Next</p>
          </Button>
        </div>
      </div>
    </div>
  )
}

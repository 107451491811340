import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { AppRoutes } from '@/constants/appRoutes'
import { useAppDispatch } from '@/hooks/redux'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useLoginMutation } from '@/store/api/Auth/Auth.query'
import { setSession } from '@/store/api/Auth/Auth.slice'
import type { LoginType } from '@/utils/schemas'
import { loginSchema } from '@/utils/schemas'

export const LoginForm = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const form = useForm<LoginType>({
    resolver: zodResolver(loginSchema),

    defaultValues: {
      email: '',
      password: '',
    },
  })

  const { handleSubmit, control } = form

  const [login, { isLoading }] = useLoginMutation()

  const makeLogin = async (password: string, email: string) => {
    try {
      const response = await login({
        email,
        password,
      }).unwrap()
      dispatch(setSession(response.info))
      navigate(AppRoutes.dashboard)
    } catch (e) {
      toast.error('Invalid credentials, please try again')
      catchWithSentry(e)
    }
  }

  const onSubmit = (data: any) => {
    makeLogin(data.password, data.email)
  }

  return (
    <div className="flex flex-col gap-8 w-full max-w-[436px]">
      <div className="flex flex-col gap-5">
        <p className="text-[40px] font-bold tracking-wide">WeCheck Admin</p>
        <p className="text-1xl text-neutral-500 tracking-tight">Login</p>
      </div>

      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
          <FormField
            control={control}
            name="email"
            render={({ field }) => (
              <FormItem className="w-full bg-white rounded-md">
                <FormControl className="w-full focus:outline-none">
                  <Input {...field} placeholder="example@justlook.com" />
                </FormControl>
                <FormMessage data-name="email" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="password"
            render={({ field }) => (
              <FormItem className="w-full bg-white rounded-md  ">
                <FormControl className="w-full focus:outline-none">
                  <Input
                    {...field}
                    placeholder="Password"
                    type="password"
                    showPassIcon
                  />
                </FormControl>
                <FormMessage data-name="password" />
              </FormItem>
            )}
          />
          <Button
            type="submit"
            className="py-3 px-5 font-medium tracking-widest h-11"
            isLoading={isLoading}
          >
            Login
          </Button>
        </form>
      </Form>
    </div>
  )
}

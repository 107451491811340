import React from 'react'

interface SubjectInputsCardTypes {
  title: string
  text: string
}

export const SubjectInputsCard = ({ title, text }: SubjectInputsCardTypes) => {
  return (
    <div className="flex flex-col gap-1 justify-start items-start h-[45px]">
      <p className="text-neutralColor80 text-base font-medium">{title}</p>
      <p className="text-neutralColor60 text-sm font-medium tracking-tight">
        {text}
      </p>
    </div>
  )
}

import { createSlice } from '@reduxjs/toolkit'

export interface reportInfoSliceTypes {
  reportInfo: {
    id: string
    subjectId: string
    pdfUrl: string
    pdfLanguage: string
    pdfCoreFindings: boolean
    pdfSocialMediaAccounts: boolean
    pdfSentimentAnalysis: boolean
    pdfFlaggedBehaviors: boolean
    pdfPositivePosts: boolean
    pdfNegativePosts: boolean
    pdfNeutralPosts: boolean
    pdfWebSearchPosts: boolean
    pdfFocusedTopics: boolean
  }
}

export const reportInfoInitialState: reportInfoSliceTypes = {
  reportInfo: {
    id: '',
    subjectId: '',
    pdfUrl: '',
    pdfLanguage: 'en',
    pdfCoreFindings: false,
    pdfSocialMediaAccounts: false,
    pdfSentimentAnalysis: false,
    pdfFlaggedBehaviors: false,
    pdfPositivePosts: false,
    pdfNegativePosts: false,
    pdfNeutralPosts: false,
    pdfWebSearchPosts: false,
    pdfFocusedTopics: false,
  },
}

const reportInfoSlice = createSlice({
  name: 'reportInfo',
  initialState: reportInfoInitialState,
  reducers: {
    setReportInfo: (state, action) => {
      const reportInfo = action.payload
      state.reportInfo = reportInfo
    },
    setScrapeData: (state, action) => {
      const reportInfo = action.payload
      state.reportInfo = reportInfo
    },

    clearReportInfo: (state) => {
      state = { ...reportInfoInitialState }
      return state
    },
  },
})

export const { setReportInfo, clearReportInfo, setScrapeData } =
  reportInfoSlice.actions

export default reportInfoSlice.reducer

import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit'
import type { Middleware } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { createBrowserHistory } from 'history'
import { combineReducers } from 'redux'
import { createReduxHistoryContext } from 'redux-first-history'

import { api } from './api'
import reportInfoReducer from './api/Admin/Admin.slice'
import authReducer from './api/Auth/Auth.slice'
import enterpriseSlice from './api/Enterprises/Enterprises.slice'
import subjectInfoReducer from './api/Subjects/Subjects.slice'
import subjectsGuardsSlice from './api/Subjects/SubjectsGuards.slice'
import uiReducer from './api/ui/ui.slice'
import userInfoReducer from './api/Users/Users.slice'
/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  () => (next) => (action: any) => {
    if (isRejectedWithValue(action)) {
      // is an known api error
      console.log(action)
    }

    return next(action)
  }

// Setup redux-first-history
const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() })
export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development' ? true : false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([api.middleware, routerMiddleware]),
  reducer: combineReducers({
    router: routerReducer,
    auth: authReducer,
    userInfo: userInfoReducer,
    ui: uiReducer,
    reportInfo: reportInfoReducer,
    subjectInfo: subjectInfoReducer,
    subjectsGuardsSlice: subjectsGuardsSlice,
    enterpriseSlice: enterpriseSlice,
    [api.reducerPath]: api.reducer,
  }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)
export const history = createReduxHistory(store)

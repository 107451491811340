import React from 'react'

import FacebookIcon from '@/assets/socialMedia/Facebook.svg?react'
import InstagramIcon from '@/assets/socialMedia/Instagram.svg?react'
import LinkedinIcon from '@/assets/socialMedia/LinkedIn.svg?react'
import TiktokIcon from '@/assets/socialMedia/TikTok.svg?react'
import TwitterIcon from '@/assets/socialMedia/X.svg?react'
import PinterestIcon from '@/assets/socialMediaLogos/pinterest.svg?react'
import RedditIcon from '@/assets/socialMediaLogos/reddit.svg?react'
import WebSearchIcon from '@/assets/socialMediaLogos/websearch.svg?react'
import { useSmallDesktopQuery } from '@/hooks/useSmallDesktopQuery'
import type { PostsList } from '@/store/api/Subjects/Subjects.types'

import { PostActions } from './PostActions'
import { PostCardContent } from './PostCardContent'

interface PostCardTypes {
  post: PostsList
  cardHeigth: number
  id: string
}

type Platform =
  | 'Pinterest'
  | 'Tiktok'
  | 'Reddit'
  | 'Twitter'
  | 'Instagram'
  | 'Facebook'
  | 'LinkedIn'
  | 'WebSearch'

export const PostCard = ({ post, cardHeigth, id }: PostCardTypes) => {
  const platformIcons: Record<Platform, JSX.Element> = {
    Pinterest: <PinterestIcon color="#EDEDED" />,
    Tiktok: <TiktokIcon color="#EDEDED" />,
    Reddit: <RedditIcon color="#EDEDED" />,
    Twitter: <TwitterIcon color="#EDEDED" />,
    Instagram: <InstagramIcon color="#EDEDED" />,
    Facebook: <FacebookIcon color="#EDEDED" />,
    LinkedIn: <LinkedinIcon color="#EDEDED" />,
    WebSearch: <WebSearchIcon color="#EDEDED" />,
  }

  const isValidPlatform = (platform: string): platform is Platform => {
    return [
      'Pinterest',
      'Tiktok',
      'Reddit',
      'Twitter',
      'Instagram',
      'Facebook',
      'LinkedIn',
      'WebSearch',
    ].includes(platform)
  }
  const isSmallDesktop = useSmallDesktopQuery()
  return (
    <div
      className={`rounded-lg bg-baseColorHighlight flex flex-col`}
      style={{ height: cardHeigth }}
    >
      <div
        className={
          isSmallDesktop
            ? 'flex flex-row gap-2 pt-2 px-2 items-center'
            : 'flex flex-row gap-2 lg:pt-4 pt-2 lg:px-4 px-2 items-center justify-between'
        }
      >
        <div className="flex gap-2 items-center">
          <div className="flex justify-center items-center bg-neutral-800 rounded-full h-10 w-10 ">
            {post &&
              isValidPlatform(post.platform) &&
              platformIcons[post.platform]}
          </div>
          <div className="flex flex-col">
            <p className="text-textNormal text-base font-medium">
              {post.type === 'WebSearch' && post.sentimentFlag !== 'Negative'
                ? 'Web/News'
                : post.type === 'WebSearch' && post.sentimentFlag === 'Negative'
                  ? 'Adverse Media'
                  : post.type}
            </p>
            <div className="flex flex-row flex-wrap">
              {post.flags.map((flag, index) => (
                <div
                  key={index}
                  className="flex flex-row content-center justify-center items-center"
                >
                  <p className="text-base text-neutralColor">{flag}</p>
                  {index < post.flags.length - 1 && (
                    <p className="text-xs text-neutralColor px-1">-</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        <PostActions post={post} id={id} />
      </div>
      <div className="flex flex-col flex-1">
        <PostCardContent
          reason={post.flagReason ?? post.sentimentReason}
          postType={post.type}
          postDate={post.date}
          content={post.content}
          imageUrl={post.mediaUrl}
          link={post.link}
          platform={post.platform}
          cardHeigth={cardHeigth}
        />
      </div>
    </div>
  )
}

import WeCheckLogo from '@/assets/sidebar/weCheckLogoProd.svg?react'

import type { ProdDevAssetsTypes } from './WecheckEnviromentDistinction.types'

export const WecheckEnviromentDistinction = ({
  enviroment,
}: ProdDevAssetsTypes) => {
  return (
    <div className="flex flex-row h-14 p-4 bg-baseColorHighlight">
      {enviroment !== 'production' ? (
        <div className="flex gap-2 items-center">
          <WeCheckLogo />
          <p className="text-sm font-medium tracking-tight">
            WeCheck AI <span className="text-dynamicGreen">DEV</span>
          </p>
        </div>
      ) : (
        <div className="flex gap-2 items-center">
          <WeCheckLogo />
          <p className="text-sm font-medium tracking-tight">WeCheck</p>
        </div>
      )}
    </div>
  )
}

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { AppRoutes } from '@/constants/appRoutes'
import { LocalStorageKey } from '@/constants/localStorageKeys'
import { selectHasToken } from '@/store/api/Auth/Auth.selector'
import { setSession } from '@/store/api/Auth/Auth.slice'
import localStorageService from '@/utils/localStorage'

import { useAppDispatch, useAppSelector } from './redux'

export const useCheckSession = () => {
  const hasSession = useAppSelector(selectHasToken)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (!hasSession) {
      const result = localStorageService.get(LocalStorageKey.token)
      if (result) {
        dispatch(setSession({ token: result }))
      }
    }
  }, [])

  useEffect(() => {
    if (!hasSession) {
      const result = localStorageService.get(LocalStorageKey.token)
      if (!result) {
        toast.info('Token expired, please login again')
        navigate(AppRoutes.login)
      }
    }
  }, [hasSession])
}

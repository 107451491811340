import { ArrowLeft2 } from 'iconsax-react'

import { Button } from '../ui/button'

import type { BackHeaderTypes } from './BackHeader.types'

export const BackHeader = ({
  backText,
  onPressBack,
  buttonStyles,
}: BackHeaderTypes) => {
  return (
    <div className="flex flex-row">
      <Button
        asChild
        size={'icon'}
        className={
          buttonStyles
            ? `${buttonStyles}`
            : 'bg-icon-fillBackground rounded-full mr-4'
        }
        variant={'outline'}
        onClick={onPressBack}
      >
        <div>
          <ArrowLeft2 />
        </div>
      </Button>

      <div className="flex justify-center items-center">
        <h1 className="text-neutral-800 text-2xl font-semibold tracking-wide">
          {backText}
        </h1>
      </div>
    </div>
  )
}

import { zodResolver } from '@hookform/resolvers/zod'
import { Image as IconImage } from 'iconsax-react'
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import emptyAvatar from '@/assets/avatarEmptyState.svg'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Switch } from '@/components/ui/switch'
import { Textarea } from '@/components/ui/text-area'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import { useUpdateSocialMediaInfoMutation } from '@/store/api/Subjects/Subjects.query'
import {
  selectSocialMedia,
  selectSubjectId,
} from '@/store/api/Subjects/Subjects.selector'
import type { updateSocialMediaInfoRequest } from '@/store/api/Subjects/Subjects.types'
import { setEditProfileDataToken } from '@/store/api/Subjects/SubjectsGuards.slice'
import type { updateSocialMediaSchemaType } from '@/utils/schemas'
import { updateSocialMediaSchema } from '@/utils/schemas'

import { useHasCurrentScreenToken } from './Guards/SubjectGuards'

export const EditProfileData = () => {
  useHasCurrentScreenToken('editProfileData')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { state } = useLocation()
  const currentProfileData = useSelector(selectSocialMedia)
  const subjectId = useSelector(selectSubjectId)
  const selectedSocialMedia = currentProfileData.find(
    (profile) => profile.name === state,
  )

  const [updateSocialMediaInfo, { isLoading }] =
    useUpdateSocialMediaInfoMutation()

  const form = useForm<updateSocialMediaSchemaType>({
    resolver: zodResolver(updateSocialMediaSchema),
    defaultValues: {
      profileImgUrl: selectedSocialMedia?.profileImgUrl,
      bio: selectedSocialMedia?.bio,
      followers: selectedSocialMedia?.followers.toString(),
      following: selectedSocialMedia?.following.toString(),
      postsCount: selectedSocialMedia?.postsCount.toString(),
      profileLink: selectedSocialMedia?.profileLink,
      isPrivate: selectedSocialMedia?.isPrivate,
      userName: selectedSocialMedia?.userName,
    },
    mode: 'onChange',
  })

  const { handleSubmit, control, watch, formState, setValue } = form
  const isPrivate = watch('isPrivate')

  const profileImgUrl = watch('profileImgUrl')
  const [previewUrl, setPreviewUrl] = useState<string>(
    selectedSocialMedia?.profileImgUrl || emptyAvatar,
  )

  useEffect(() => {
    if (profileImgUrl) {
      setPreviewUrl(profileImgUrl?.trim() || emptyAvatar)
    } else {
      setPreviewUrl(emptyAvatar)
    }
  }, [profileImgUrl])

  const handleUpdateSocialMediaInfo = async (data: any) => {
    const followers = Number(data.followers as string)
    const following = Number(data.following as string)
    const postsCount = Number(data.postsCount as string)

    try {
      const requestInfo: updateSocialMediaInfoRequest = {
        id: selectedSocialMedia?.id ?? '',
        subjectId: subjectId || '',
        profileImgUrl: data.profileImgUrl,
        profileLink: data.profileLink,
        followers: followers,
        following: following,
        postsCount: postsCount,
        bio: data.bio,
        isPrivate: data.isPrivate,
        userName: data.userName,
      }
      await updateSocialMediaInfo(requestInfo).unwrap()
      toast.success('Successfully Updated Social Media')
      dispatch(setEditProfileDataToken(false))
      navigate(AppRoutes.socialMedia)
    } catch (e) {
      toast.error('Error Updating Social Media')
      console.error(e)
    }
  }

  const onSubmit = (data: any) => {
    handleUpdateSocialMediaInfo(data)
  }

  const handleCancel = () => {
    dispatch(setEditProfileDataToken(false))
    navigate(AppRoutes.socialMedia)
  }

  return (
    <WeScreen
      boxCustomPadding={16}
      useNextButton={false}
      isLoading={isLoading}
      onClickBack={handleCancel}
      rightSideHeaderContent={
        <div className="flex gap-3 items center">
          <Button variant={'secondary'} onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            type="submit"
            form="form"
            onClick={handleSubmit(onSubmit)}
            disabled={!formState.isValid}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form {...form}>
        <form className="flex flex-col gap-8">
          <div className="flex items-center justify-start gap-5">
            <div className="border border-primaryColor30 rounded-full w-12 h-12 ">
              <div className="flex justify-center items-center h-12">
                {previewUrl ? (
                  <img
                    alt="Profile"
                    src={previewUrl}
                    className="w-full h-full rounded-full"
                  />
                ) : (
                  <IconImage
                    className="border border-neutralColor rounded-sm"
                    size={16}
                  />
                )}
              </div>
            </div>
            <p className="text-neutralColor text-xl font-medium">
              {selectedSocialMedia?.userName}
            </p>
          </div>
          <div className="flex flex-col gap-5">
            <p className="text-neutralColor text-base font-medium">
              Profile Data
            </p>
            <div className="flex flex-wrap gap-y-6 justify-between items-end">
              <FormField
                control={control}
                name="followers"
                render={({ field }) => (
                  <FormItem className="w-[32%]">
                    <FormLabel>Followers</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage data-name="followers" />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="following"
                render={({ field }) => (
                  <FormItem className="w-[32%]">
                    <FormLabel>Following</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage data-name="following" />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="postsCount"
                render={({ field }) => (
                  <FormItem className="w-[32%]">
                    <FormLabel>Posts Count</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage data-name="postsCount" />
                  </FormItem>
                )}
              />

              <FormField
                control={control}
                name="bio"
                render={({ field }) => (
                  <FormItem className="w-[32%]">
                    <FormLabel>
                      Bio{' '}
                      <span className="text-neutralColor60">(Optional)</span>
                    </FormLabel>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage data-name="bio" />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="profileLink"
                render={({ field }) => (
                  <FormItem className="w-[32%]">
                    <FormLabel>Profile Link</FormLabel>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage data-name="profileLink" />
                  </FormItem>
                )}
              />
              <FormField
                name="profileImgUrl"
                render={({ field }) => (
                  <FormItem className="w-[32%]">
                    <FormLabel>
                      Profile Img Url{' '}
                      <span className="text-neutralColor60">(Optional)</span>
                    </FormLabel>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage data-name="profileImgUrl" />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="userName"
                render={({ field }) => (
                  <FormItem className="w-[32%]">
                    <FormLabel>
                      Username{' '}
                      <span className="text-neutralColor60">(Optional)</span>
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage data-name="userName" />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="flex flex-col gap-5">
            <p className="text-neutralColor text-base font-medium">Privacy</p>
            <div className="flex justify-between">
              <FormField
                name="isPrivate"
                render={({ field }) => (
                  <FormItem className="w-1/2 h-12 px-3 bg-baseColorHighlight rounded-2xl border border-neutralColor60 flex items-center justify-between">
                    <p className="text-neutral-800 text-base font-medium">
                      {isPrivate ? 'Privado' : 'Público'}
                    </p>
                    <FormControl>
                      <Switch
                        {...field}
                        className="w-12 h-6"
                        checked={isPrivate}
                        onCheckedChange={(checked) => {
                          setValue('isPrivate', checked)
                        }}
                      />
                    </FormControl>
                    <FormMessage data-name="isPrivate" />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </form>
      </Form>
    </WeScreen>
  )
}

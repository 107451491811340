import { format } from 'date-fns'
import { useState } from 'react'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { useUploadPdfMutation } from '@/store/api/Admin/Admin.query'
import type { uploadPdfRequest } from '@/store/api/Admin/Admin.types'
import { getFileSize } from '@/utils/getFileSize'

interface ReplacePdfPopUpTypes {
  id: string
}

export const ReplacePdfPopUp = ({ id }: ReplacePdfPopUpTypes) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [open, setOpen] = useState(false)
  const [uploadPdf, { isLoading }] = useUploadPdfMutation()

  const handleFileUpload = (event: any) => {
    setSelectedFile(event.target.files[0])
  }
  const handleUpload = async () => {
    if (!selectedFile) {
      toast.error('Please select a file.')
      return
    }

    try {
      const formData = new FormData()
      formData.append('file', selectedFile)
      const requestInfo: uploadPdfRequest = {
        file: formData,
        socialCheckId: id || '',
      }
      await uploadPdf(requestInfo).unwrap()
      toast.success('Changes saved successfully.')
      setOpen(false)
    } catch (e) {
      toast.error('Error saving changes.')
      console.error(e)
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button variant={'secondary'}>Replace PDF</Button>
      </DialogTrigger>
      <DialogContent maxWidth="max-w-3xl">
        <DialogHeader className="flex gap-3">
          <DialogTitle className="text-2xl font-medium text-neutralColor">
            Replace PDF
          </DialogTitle>
          <DialogDescription className="text-base font-normal text-neutralColor">
            You are about to replace the existing PDF with a new one. Please
            ensure that the new file contains the correct information before
            proceeding.
          </DialogDescription>
        </DialogHeader>
        {selectedFile && (
          <div className="flex flex-col justify-start gap-1">
            <div className="flex items-center gap-1">
              <p className="text-neutralColor text-base font-normal">
                File Name:
              </p>
              <p className="text-neutralColor text-base font-normal">
                {selectedFile?.name}
              </p>
            </div>
            <div className="flex items-center gap-1">
              <p className="text-neutralColor text-base font-normal">
                File Size:
              </p>
              <p className="text-neutralColor text-base font-normal">
                {getFileSize(selectedFile?.size || 0)}
              </p>
            </div>
            <div className="flex items-center gap-1">
              <p className="text-neutralColor text-base font-normal">
                Last Update:
              </p>
              <p className="text-neutralColor text-base font-normal">
                {format(
                  new Date(selectedFile?.lastModified || 0),
                  'MM/dd/yyyy - HH:mm',
                )}
              </p>
            </div>
          </div>
        )}

        <DialogFooter>
          <div className="flex gap-2">
            <div
              role="button"
              id="pdf"
              className="h-[46px] px-5 py-3 bg-primaryColor5 rounded-xl border"
            >
              <Input
                className="absolute w-[90px] h-[33px]"
                style={{ opacity: 0 }}
                accept="image/*,.pdf"
                type="file"
                onChange={handleFileUpload}
              />
              {selectedFile ? selectedFile.name : 'Choose File'}
            </div>
            <Button
              type="submit"
              className="py-3 px-5 font-medium tracking-widest h-[46px] flex "
              onClick={handleUpload}
              isLoading={isLoading}
              disabled={!selectedFile}
            >
              Confirm
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

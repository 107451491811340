export const filterEmptyFields = (
  obj: any,
  key1?: string,
  key2?: string,
  key3?: string,
) => {
  const filteredObj: any = {}
  const allowedKeys = ['id']

  if (key1) allowedKeys.push(key1)
  if (key2) allowedKeys.push(key2)
  if (key3) allowedKeys.push(key3)

  Object.keys(obj).forEach((key) => {
    if ((obj[key] !== '' && obj[key] !== null) || allowedKeys.includes(key)) {
      filteredObj[key] = obj[key]
    }
  })

  return filteredObj
}

import { RTKTags, api } from '@/store/api'

import Env from '../../../env/env'

import type { LoginRequest, LoginResponse } from './Auth.types'

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/auth/admin-sign-in`,
        method: 'POST',
        body: {
          email: request.email,
          password: request.password,
        },
      }),
    }),

    logout: builder.mutation<void, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/health`,
        method: 'GET',
      }),
      invalidatesTags: [
        RTKTags.userInfo,
        RTKTags.userHistory,
        RTKTags.Credits,
        RTKTags.Home,
      ],
    }),
  }),
})

export const { useLoginMutation, useLogoutMutation } = authApi

import { HambergerMenu, Menu, LoginCurve, UserSquare } from 'iconsax-react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import W from '@/assets/W.png'
import WeCheckSocialLogoBlack from '@/assets/wecheckSocialBlack.svg?react'
import WeCheckSocialLogo from '@/assets/weCheckWhite.svg?react'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import useSmallMobileMediaQuery from '@/hooks/useSmallMobileMediaQuery'
import useTabletMediaQuery from '@/hooks/useTabletMediaQuery'

import { Button } from '../ui/button'
import { NavigationMenu, NavigationMenuItem } from '../ui/navigation-menu'

interface FormsScreensHeaderTypes {
  login?: boolean
  onlyLogo?: boolean
  signUp?: boolean
}

export const FormsScreensHeader = ({
  login,
  onlyLogo,
  signUp,
}: FormsScreensHeaderTypes) => {
  const [toggle, setToggle] = useState(false)
  const isMobile = useMobileMediaQuery()
  const isSmallMobile = useSmallMobileMediaQuery()
  const isTablet = useTabletMediaQuery()
  const isHide = true

  return (
    <>
      {isMobile || isSmallMobile || isTablet ? (
        <div className="lg:hidden flex flex-1 justify-end items-center mt-6 bg-white">
          <div className="w-full flex justify-between items-center max-w-7xl mx-auto ml-4">
            <Link to="/">
              <img
                src={W}
                alt="justLook"
                className="object-contain"
                width={52}
                height={27}
              />
            </Link>
          </div>
          {!isHide && (
            <Button
              variant="link"
              onClick={() => setToggle(!toggle)}
              className={`${onlyLogo && 'hidden'} mr-5`}
            >
              {toggle ? (
                <Menu color="#262626" className="bg-transparent" />
              ) : (
                <HambergerMenu color="#262626" />
              )}
            </Button>
          )}
          {!isHide && (
            <div
              className={`${!toggle ? 'hidden' : 'flex'} 
            p-6 bg-white absolute top-16 right-0 mx-4 my-2 min-w-[140px] z-10 rounded-xl border-solid border-[1px] border-neutral-800
          `}
            >
              {!isHide && (
                <NavigationMenuItem
                  className={`${
                    onlyLogo && 'hidden'
                  }list-none flex justify-end items-start flex-col gap-2`}
                >
                  <Link to="/login/access" className={`${login && 'hidden'}`}>
                    <Button variant="link">
                      Login
                      <LoginCurve color="#262626" className="ml-2" />
                    </Button>
                  </Link>
                  <Link
                    to="/signUp/start"
                    className={`${signUp && 'hidden '}}`}
                  >
                    <Button variant={`${login ? 'link' : 'default'}`}>
                      Sign Up
                      <UserSquare
                        color={`${login ? '#252525' : '#fafafa'}`}
                        className="ml-2"
                      />
                    </Button>
                  </Link>
                </NavigationMenuItem>
              )}
            </div>
          )}
        </div>
      ) : (
        <NavigationMenu
          className={
            'pl-12 max-w-8xl pb-4 pt-4 bg-transparent absolute  w-full'
          }
        >
          <div className="w-full pl-1 lg:pl-0 xl:pl-10 2xl:pl-20">
            <Link to="/">
              <WeCheckSocialLogo className="max-lg:hidden" />
              <WeCheckSocialLogoBlack className="lg:hidden" />
            </Link>
          </div>
          {!isHide && (
            <NavigationMenuItem
              className={`${onlyLogo && 'hidden'} 
           flex space-x-4 
          `}
            >
              <Link to="/login/access" className={`${login && 'hidden'}`}>
                <Button variant="link" className="mr-20 max-lg:mr-4">
                  Login
                  <LoginCurve color="#262626" className="ml-2" />
                </Button>
              </Link>
              <Link to="/signUp/start" className={`${signUp && 'hidden'}`}>
                <Button
                  variant={`${login ? 'link' : 'default'}`}
                  className="mr-20 max-lg:mr-8"
                >
                  Sign Up
                  <UserSquare
                    color={`${login ? '#252525' : '#fafafa'}`}
                    className="ml-2"
                  />
                </Button>
              </Link>
            </NavigationMenuItem>
          )}
        </NavigationMenu>
      )}
    </>
  )
}

import { createSlice } from '@reduxjs/toolkit'

export interface subjectsGuardsSliceState {
  editSubjectToken: boolean
  editCoreFindingsToken: boolean
  createPlatformToken: boolean
  editSubjectInfoToken: boolean
  platformAndPostsEditionToken: boolean
  editProfileDataToken: boolean
  editPostToken: boolean
  createPostToken: boolean
}

export const subjectsGuardsSliceInitialState: subjectsGuardsSliceState = {
  editSubjectToken: false,
  editCoreFindingsToken: false,
  createPlatformToken: false,
  editSubjectInfoToken: false,
  platformAndPostsEditionToken: false,
  editProfileDataToken: false,
  editPostToken: false,
  createPostToken: false,
}

export const subjectsGuardsSlice = createSlice({
  name: 'subjectsGuardsSlice',
  initialState: subjectsGuardsSliceInitialState,
  reducers: {
    setEditSubjectToken: (state, action) => {
      const editSubjectToken = action.payload
      state.editSubjectToken = {
        ...editSubjectToken,
      }
    },
    setEditCoreFindingsToken: (state, action) => {
      const editCoreFindingsToken = action.payload
      state.editCoreFindingsToken = { ...editCoreFindingsToken }
    },
    setCreatePlatformToken: (state, action) => {
      const createPlatformToken = action.payload
      state.createPlatformToken = { ...createPlatformToken }
    },
    setEditSubjectInfoToken: (state, action) => {
      const editSubjectInfoToken = action.payload
      state.editSubjectInfoToken = { ...editSubjectInfoToken }
    },
    setPlatformAndPostsEditionToken: (state, action) => {
      const platformAndPostsEditionToken = action.payload
      state.platformAndPostsEditionToken = {
        ...platformAndPostsEditionToken,
      }
    },
    setEditProfileDataToken: (state, action) => {
      const editProfileDataToken = action.payload
      state.editProfileDataToken = {
        ...editProfileDataToken,
      }
    },
    setEditPostToken: (state, action) => {
      const editPostToken = action.payload
      state.editPostToken = { ...editPostToken }
    },
    setCreatePostToken: (state, action) => {
      const createPostToken = action.payload
      state.createPostToken = { ...createPostToken }
    },
    clearTokens: (state) => {
      state = subjectsGuardsSliceInitialState
      return state
    },
  },
})

export const {
  setEditSubjectToken,
  setEditCoreFindingsToken,
  setCreatePlatformToken,
  setEditSubjectInfoToken,
  setPlatformAndPostsEditionToken,
  setEditProfileDataToken,
  setEditPostToken,
  setCreatePostToken,
  clearTokens,
} = subjectsGuardsSlice.actions

export default subjectsGuardsSlice.reducer

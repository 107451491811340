import { zodResolver } from '@hookform/resolvers/zod'
import { AddCircle } from 'iconsax-react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { toast } from 'sonner' // Import toast for notifications
import type { z } from 'zod'

import { Chip } from '@/components/Chip/Chip'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { Form, FormField, FormLabel } from '@/components/ui/form'
import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetFooter,
} from '@/components/ui/sheet'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useChangeReportTopicsMutation } from '@/store/api/Subjects/Subjects.query'
import {
  selectSubjectId,
  selectSubjectTopics,
} from '@/store/api/Subjects/Subjects.selector'
import { useListPaginatedOfTopicsQuery } from '@/store/api/Topics/Topics.query'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import { changeTopicsSchema } from '@/utils/schemas'

type ChangeTopicsForm = z.infer<typeof changeTopicsSchema>

export const FocusedTopicsSheet = () => {
  const subjectId = useSelector(selectSubjectId) || ''
  const subjectTopics = useSelector(selectSubjectTopics)

  const { data: topicsData } = useListPaginatedOfTopicsQuery({})

  const [changeTopics, { isLoading: isLoadingChangeReportTopics }] =
    useChangeReportTopicsMutation()

  const form = useForm<ChangeTopicsForm>({
    resolver: zodResolver(changeTopicsSchema),
    defaultValues: {},
  })

  const { handleSubmit, control, reset } = form

  useEffect(() => {
    if (topicsData && subjectTopics) {
      const defaultValues: ChangeTopicsForm = topicsData?.info.list.reduce(
        (acc, topic) => {
          acc[topic.name] = subjectTopics.includes(topic.name)
          return acc
        },
        {} as ChangeTopicsForm,
      )

      reset(defaultValues)
    }
  }, [topicsData, subjectTopics, reset])

  const onSubmit = async (formData: ChangeTopicsForm) => {
    const selectedTopics =
      topicsData?.info.list
        .filter((topic) => formData[topic.name])
        .map((topic) => ({
          id: topic._id,
          name: topic.name,
        })) || []

    const topicsNames = selectedTopics.map((topic) => topic.name)
    const topicsIds = selectedTopics.map((topic) => topic.id)

    try {
      await changeTopics({ subjectId, topics: topicsNames, topicsIds }).unwrap()
      toast.success('Topics edited successfully.')
    } catch (e) {
      catchWithSentry(e)
      toast.error('Error editing topics.')
    }
  }

  return (
    <Sheet>
      <SheetTrigger className="hover:bg-primaryColor5 rounded-xl">
        <Chip
          text="Add"
          icon={<AddCircle size={16} color={weCheckPalette.neutralColor60} />}
          isTag
          dashed
        />
      </SheetTrigger>
      <SheetContent className="flex flex-col justify-between">
        <div className="flex flex-col gap-6 ">
          <SheetHeader>
            <SheetTitle>Focused Topics</SheetTitle>
          </SheetHeader>
          <Form {...form}>
            <form className="flex flex-col">
              <div className="flex flex-col gap-y-5">
                {topicsData?.info.list.map((topic) => (
                  <FormField
                    key={topic._id}
                    control={control}
                    name={topic.name as keyof ChangeTopicsForm}
                    render={({ field }) => (
                      <div className="flex justify-between items-center">
                        <FormLabel className="text-neutralColor60 text-sm font-medium tracking-tight">
                          {topic.name}
                        </FormLabel>
                        <Checkbox
                          hiddenCheckIcon
                          className="rounded-full data-[state=checked]:bg-primaryColor border-4 border-staticWhite shadow-neutralColor80 shadow-sm"
                          checked={field.value || false}
                          onCheckedChange={field.onChange}
                        />
                      </div>
                    )}
                  />
                ))}
              </div>
            </form>
          </Form>
        </div>
        <SheetFooter className="flex">
          <Button type="button" variant="secondary" className="w-full">
            Cancel
          </Button>
          <Button
            isLoading={isLoadingChangeReportTopics}
            onClick={handleSubmit(onSubmit)}
            className="w-full"
          >
            Add
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}

import type {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
} from '@tanstack/react-table'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { isEmpty } from 'ramda'
import * as React from 'react'
import { useMemo, useState } from 'react'
import { toast } from 'sonner'

import { ActionsSelector } from '@/components/ActionsSelector/ActionsSelector'
import { Chip } from '@/components/Chip/Chip'
import { EmptyState } from '@/components/EmptyState/EmptyState'
import { RoleSelector } from '@/components/RoleSelector/RoleSelector'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useUsersRolAndStateMutation } from '@/store/api/Admin/Admin.query'
import type { UserResponse } from '@/store/api/Admin/Admin.types'
import { filterEmptyFields } from '@/utils/filterEmptyFields'

export function UsersTable({ data }: { data: UserResponse }) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = useState({})
  const [globalFilter, setGlobalFilter] = useState('')

  const [updateRoleAndStatus, { isLoading }] = useUsersRolAndStateMutation()

  const handleUpdateRolAndStatus = async (
    selectedRole: string | null,
    selectedStatus: string | null,
    id: string,
  ) => {
    const request = {
      id: id,
      status: selectedStatus,
      role: selectedRole,
    }
    const filteredRequest = filterEmptyFields(request, 'id')
    try {
      await updateRoleAndStatus(filteredRequest).unwrap()
      toast.success('Changes saved successfully')
    } catch (error) {
      toast.error('Error saving changes.')
      catchWithSentry(error)
    }
  }

  const statusCounts = useMemo(() => {
    if (!data) return {}

    const counts: any = {
      active: 0,
      pending: 0,
      pending_on_boarding: 0,
      blocked: 0,
      deleted: 0,
      pending_email_verification: 0,
      undefined: 0,
    }

    data.forEach((user) => {
      counts[user.status] += 1
    })

    return counts
  }, [data])

  const columns: ColumnDef<any>[] = [
    {
      accessorKey: 'firstname',
      header: 'User',
      cell: ({ row }) => row.getValue('firstname') || 'Nameless',
    },
    {
      accessorKey: 'email',
      header: 'Email',
      cell: ({ row }) => row.getValue('email') || 'No Email',
    },
    {
      accessorKey: 'enterprise',
      header: 'Company',
      cell: ({ row }) => row.getValue('enterprise') || 'No Company',
    },
    {
      accessorKey: 'role',
      header: 'Role',
      cell: ({ row }) => (
        <RoleSelector
          id={row.original.id}
          currentRole={row.original.role}
          updateRole={handleUpdateRolAndStatus}
          isLoading={isLoading}
        />
      ),
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row }: any) => (
        <Chip
          text={
            row.getValue('status') === 'pending'
              ? 'Pending'
              : row.getValue('status') === 'active'
                ? 'Active'
                : row.getValue('status') === 'pending_on_boarding'
                  ? 'Pending Onboarding'
                  : row.getValue('status') === 'deleted'
                    ? 'Deleted'
                    : row.getValue('status') === 'blocked'
                      ? 'Blocked'
                      : row.getValue('status') === 'pending_email_verification'
                        ? 'Pending Email Verification'
                        : 'Undefined'
          }
          isTag
          blue={row.getValue('status') === 'pending'}
          green={row.getValue('status') === 'active'}
          yellow={row.getValue('status') === 'pending_on_boarding'}
          red={
            row.getValue('status') === 'deleted' ||
            row.getValue('status') === 'blocked'
          }
          primaryBlue={row.getValue('status') === 'pending_email_verification'}
          dashed={row.getValue('status') === undefined}
        />
      ),
    },
    {
      id: 'Actions',
      cell: ({ row }) => (
        <div className="flex justify-end">
          <ActionsSelector
            id={row.original.id}
            updateStatus={handleUpdateRolAndStatus}
            currentStatus={row.original.status}
          />
        </div>
      ),
    },
  ]

  const table = useReactTable({
    data: data ?? [],
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      globalFilter,
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  if (isEmpty(data)) {
    return <EmptyState text="We will show here to users when there are any" />
  }

  return (
    <div className="flex flex-col w-full p-4 max-xl:gap-4 gap-6">
      <div className="flex items-center justify-between">
        <Input
          placeholder="Filter by any element..."
          value={globalFilter}
          onChange={(event) => {
            const value = event.target.value
            setGlobalFilter(value)
          }}
          className="h-10 border border-neutralColor30 rounded-lg w-[376px]"
        />
      </div>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
                className="bg-backgroundHighlight hover:bg-muted/50 "
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    className="text-sm font-medium tracking-tighter"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="text-center">
                <EmptyState text="There seem to be results for your search" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className="flex gap-5 py-4">
        {statusCounts.active > 0 && (
          <Chip isTag green text={`Active ${statusCounts.active}`} />
        )}
        {statusCounts.pending > 0 && (
          <Chip isTag blue text={`Pending ${statusCounts.pending}`} />
        )}
        {statusCounts.pending_on_boarding > 0 && (
          <Chip
            isTag
            yellow
            text={`Pending Onboarding ${statusCounts.pending_on_boarding}`}
          />
        )}
        {statusCounts.blocked > 0 && (
          <Chip isTag red text={`Blocked ${statusCounts.blocked}`} />
        )}
        {statusCounts.deleted > 0 && (
          <Chip isTag red text={`Deleted ${statusCounts.deleted}`} />
        )}
        {statusCounts.pending_email_verification > 0 && (
          <Chip
            isTag
            primaryBlue
            text={`Pending Email Verification ${statusCounts.pending_email_verification}`}
          />
        )}
        {statusCounts.undefined > 0 && (
          <Chip isTag dashed text={`Undefined ${statusCounts.undefined}`} />
        )}
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="space-x-2">
          <Button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="h-10 px-4 py-2 rounded-lg border border-primary30 bg-primaryColor5"
          >
            <p className="text-neutralColor text-base font-normal">Previous</p>
          </Button>
          <Button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="h-10 px-4 py-2 rounded-lg border border-primary30 bg-primaryColor5"
          >
            <p className="text-neutralColor text-base font-normal">Next</p>
          </Button>
        </div>
      </div>
    </div>
  )
}

import { useEffect } from 'react'

import { WeScreen } from '@/components/WeScreen/WeScreen'
import { useAppSelector } from '@/hooks/redux'
import { useRedirectBasedOnPermissions } from '@/hooks/useRedirectBasedOnPermissions'
import { useGetEnterprisesQuery } from '@/store/api/Enterprises/Enterprises.query'
import { selectItsDefaultState } from '@/store/api/Users/Users.selector'

import { CompaniesTable } from './Components/CompaniesTable'

export const Companies = () => {
  const { hasHandleEnterprises } = useRedirectBasedOnPermissions()
  const itsDefaultState = useAppSelector(selectItsDefaultState)

  useEffect(() => {
    if (!itsDefaultState) hasHandleEnterprises()
  }, [itsDefaultState])

  const { data, isLoading, isError } = useGetEnterprisesQuery({})

  return (
    <WeScreen
      title="Companies"
      useHeaderBackButton={false}
      useNextButton={false}
      isLoading={isLoading}
      isError={isError}
    >
      {data && <CompaniesTable data={data?.info.list} />}
    </WeScreen>
  )
}

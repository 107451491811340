import { RTKTags, api } from '@/store/api'

import Env from '../../../env/env'

import type {
  IUploadCsvCrudResponse,
  IUploadCsvRequest,
  IUploadCsvResponse,
} from './Batch.types'

export const batchApi = api.injectEndpoints({
  endpoints: (builder) => ({
    uploadCSV: builder.mutation<IUploadCsvResponse[], IUploadCsvRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/reports/batch/${request.id}`,
        method: 'POST',
        body: request.file,
      }),
      transformResponse: (response: IUploadCsvCrudResponse) => {
        return response.info
      },
      invalidatesTags: [RTKTags.enterprises],
    }),
  }),
})

export const { useUploadCSVMutation } = batchApi

import { More } from 'iconsax-react'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { ConfirmActionPopUp } from '@/components/ConfirmActionPopUp/ConfirmActionPopUp'
import { Button } from '@/components/ui/button'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
} from '@/components/ui/select'
import { AppRoutes } from '@/constants/appRoutes'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import {
  useReAnalyzeAndGeneratePdfMutation,
  useRegenerateReportMutation,
  useRegenerateWithoutIAMutation,
  useRegenerateWithSummaryMutation,
} from '@/store/api/Admin/Admin.query'
import { setEditSubjectInfoToken } from '@/store/api/Subjects/SubjectsGuards.slice'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

interface ActionsHeaderTypes {
  subjectId: string
  wecheckScraped?: boolean
}

export const ActionsHeader = ({
  subjectId,
  wecheckScraped = false,
}: ActionsHeaderTypes) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [openRegenerateWithoutIaDialog, setOpenRegenerateWithoutIaDialog] =
    useState(false)
  const [openRegenerateSummaryDialog, setOpenRegenerateSummaryDialog] =
    useState(false)
  const [openRegenerateWithIaDialog, setOpenRegenerateWithIaDialog] =
    useState(false)
  const [
    openReAnalyzeAndGeneratePdfDialog,
    setOpenReAnalyzeAndGeneratePdfDialog,
  ] = useState(false)

  const [regenerateReport, { isLoading: isLoadingRegenerateReport }] =
    useRegenerateReportMutation()

  const [
    regenerateWithoutAIReport,
    { isLoading: isLoadingRegenerateReportWithoutAI },
  ] = useRegenerateWithoutIAMutation()

  const [regenerateWithSummary, { isLoading: isLoadingRegenerateWithSummary }] =
    useRegenerateWithSummaryMutation()

  const [
    reAnalyzeAndGeneratePdf,
    { isLoading: isLoadingReAnalyzeAndGeneratePdf },
  ] = useReAnalyzeAndGeneratePdfMutation()

  const handleRegenerateReport = async () => {
    try {
      await regenerateReport({ id: subjectId }).unwrap()
      toast.success('Report regenerated successfully')
    } catch (e) {
      toast.error('error regenerating report')
      catchWithSentry(e)
      console.error(e)
    }
  }

  const handleRegenerateWithoutAIReport = async () => {
    try {
      await regenerateWithoutAIReport({ id: subjectId }).unwrap()
      toast.success('Report regenerated without using AI !! ')
    } catch (e) {
      toast.error('error regenerating report')
      catchWithSentry(e)
      console.error(e)
    }
    setOpenRegenerateWithoutIaDialog(false)
  }

  const handleRegenerateSummaryReport = async () => {
    try {
      await regenerateWithSummary({ id: subjectId }).unwrap()
      toast.success('Report regenerated with new Summary !! ')
    } catch (e) {
      toast.error('error regenerating report')
      catchWithSentry(e)
      console.error(e)
    }
    setOpenRegenerateSummaryDialog(false)
  }

  const handleReAnalyzeAndGeneratePdf = async () => {
    try {
      await reAnalyzeAndGeneratePdf({ id: subjectId }).unwrap()
      toast.success(
        'Report regenerated with post re analized and new summary !! ',
      )
    } catch (e) {
      toast.error('error regenerating report')
      catchWithSentry(e)
      console.error(e)
    }
    setOpenReAnalyzeAndGeneratePdfDialog(false)
  }

  const handleNavigateEditPersonalInfo = () => {
    dispatch(setEditSubjectInfoToken(true))
    navigate(AppRoutes.modifyPersonalInfo)
  }

  return (
    <Select>
      <SelectTrigger
        className="w-10 h-10 border-none shadow-none p-1 "
        hiddenArrow
      >
        <More color={weCheckPalette.neutralColor} size={30} />
      </SelectTrigger>
      <SelectContent className="w-[224px]">
        <SelectGroup>
          <SelectLabel>Actions</SelectLabel>
          <SelectSeparator />

          <ConfirmActionPopUp
            open={openRegenerateWithoutIaDialog}
            setOpen={setOpenRegenerateWithoutIaDialog}
            onClick={handleRegenerateWithoutAIReport}
            isLoading={isLoadingRegenerateReportWithoutAI}
            TriggerText="Refresh Report"
            Title="Refresh Report"
            Description="By selecting this option, the report will be refreshed using the current data provided. This action will update the report without any changes to the existing analysis or data."
            ConfirmText="This action is free and will not incur any costs."
            customTriggerStyle="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2  outline-none focus:bg-primaryColor5 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 h-[35px] text-neutralColor60 text-sm font-medium tracking-tight hover:bg-primaryColor5 text-left"
          />

          <ConfirmActionPopUp
            open={openRegenerateSummaryDialog}
            setOpen={setOpenRegenerateSummaryDialog}
            confirmTextRed
            onClick={handleRegenerateSummaryReport}
            isLoading={isLoadingRegenerateWithSummary}
            TriggerText="Create New Summary"
            Title="Create New Summary"
            ConfirmText="This action will have a small cost."
            Description="This option will update the core findings based on the new information you’ve added. It only changes the core findings and won’t affect other parts of the report."
            customTriggerStyle="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2  outline-none focus:bg-primaryColor5 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 h-[35px] text-neutralColor60 text-sm font-medium tracking-tight hover:bg-primaryColor5 text-left"
          />

          <ConfirmActionPopUp
            open={openReAnalyzeAndGeneratePdfDialog}
            setOpen={setOpenReAnalyzeAndGeneratePdfDialog}
            confirmTextRed
            onClick={handleReAnalyzeAndGeneratePdf}
            isLoading={isLoadingReAnalyzeAndGeneratePdf}
            TriggerText="Run New Analysis"
            Title="Run New Analysis"
            Description="Selecting this option will re-analyze the entire report using AI, applying the latest data and insights. This process updates all sections of the report to reflect the most current information."
            customTriggerStyle="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2  outline-none focus:bg-primaryColor5 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 h-[35px] text-neutralColor60 text-sm font-medium tracking-tight hover:bg-primaryColor5 text-left"
            ConfirmText="This action will incur an additional cost."
          />
          {!wecheckScraped && (
            <ConfirmActionPopUp
              open={openRegenerateWithIaDialog}
              setOpen={setOpenRegenerateWithIaDialog}
              confirmTextRed
              isLoading={isLoadingRegenerateReport}
              onClick={handleRegenerateReport}
              Title="Replace with New Report"
              Description="This option will replace the existing report with a newly generated one based on the latest data. It’s like starting from scratch, and this action incurs the full cost of generating a new report."
              TriggerText="Replace with New Report"
              ConfirmText="This action will incur the full cost of a new report."
              customTriggerStyle="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2  outline-none focus:bg-primaryColor5 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 h-[35px] text-neutralColor60 text-sm font-medium tracking-tight hover:bg-primaryColor5 text-left"
            />
          )}
          <Button
            variant={'outline'}
            className="border-none shadow-none p-0 w-full"
            onClick={handleNavigateEditPersonalInfo}
          >
            <p className="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2  outline-none focus:bg-primaryColor5 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 h-[35px] text-neutralColor60 text-sm font-medium tracking-tight hover:bg-primaryColor5 text-left">
              Edit Subject Information
            </p>
          </Button>
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}

import React from 'react'
import { Outlet } from 'react-router-dom'

import WeCheckLeftScreen from '@/assets/contactUs.png'
import { useSmallDesktopQuery } from '@/hooks/useSmallDesktopQuery'

import { FormsScreensHeader } from '../FormsScreensHeader/FormsScreensHeader'

export const UnloggedScreenLayout = () => {
  const isSmallDesktop = useSmallDesktopQuery()

  return (
    <div className="flex flex-row h-screen max-lg:flex-col bg-white">
      <FormsScreensHeader />
      {isSmallDesktop ? (
        <div className="flex justify-center items-center bg-center basis-1/2 h-full max-lg:hidden">
          <img src={WeCheckLeftScreen} alt="wecheckLeftScreen" />
        </div>
      ) : (
        <div className="flex w-full h-full bg-center basis-1/2 max-lg:hidden">
          <img
            src={WeCheckLeftScreen}
            className="h-full w-auto"
            alt="wecheckLeftScreen"
          />
        </div>
      )}

      <div className="flex w-full h-full justify-center items-center bg-white basis-1/2 px-32 max-sm:px-4 max-lg:basis-full">
        <Outlet />
      </div>
    </div>
  )
}

import { api, RTKTags } from '@/store/api'

import Env from '../../../env/env'

import type {
  createNewTopicsRequest,
  listPaginatedOfTopicsRequest,
  listPaginatedOfTopicsResponse,
  updateTopicRequest,
} from './Topics.types'

export const topicsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listPaginatedOfTopics: builder.query<
      listPaginatedOfTopicsResponse,
      listPaginatedOfTopicsRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v2/admin/topics`,
        method: 'GET',
        params: {
          page: 1,
          pageSize: 20,
          enterprise: request.enterprise,
          status: request.status,
        },
      }),
      providesTags: [RTKTags.topics],
    }),
    createNewTopic: builder.mutation<void, createNewTopicsRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v2/admin/topics`,
        method: 'POST',
        body: {
          name: request.name,
          enterprise: request.enterprise,
          translations: request.translations,
          promp: request.promp,
        },
      }),
      invalidatesTags: [RTKTags.topics],
    }),
    updateTopic: builder.mutation<void, updateTopicRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/topics/${request.id}`,
        method: 'PATCH',
        body: {
          name: request.name,
          enterprise: request.enterprise,
          translations: request.translations,
          promp: request.promp,
        },
      }),
      invalidatesTags: [RTKTags.topics],
    }),
  }),
})

export const {
  useListPaginatedOfTopicsQuery,
  useCreateNewTopicMutation,
  useUpdateTopicMutation,
} = topicsApi

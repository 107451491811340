import { createSlice } from '@reduxjs/toolkit'

import type { SocialMedia, subjectInfo } from './Subjects.types'

export interface subjectInfoTypes {
  subjectInfo: subjectInfo | null
  selectedCreatePostFlags: string[]
  selectedEditPostFlags: string[]
  currentPlatform: {
    platform: string
    platformData: SocialMedia
  }
}

export const subjectInfoInitialState: subjectInfoTypes = {
  subjectInfo: null,
  selectedCreatePostFlags: [],
  selectedEditPostFlags: [],
  currentPlatform: {
    platform: '',
    platformData: {
      name: '',
      profileImgUrl: '',
      userName: '',
      profileLink: '',
      followers: 0,
      following: 0,
      postsCount: 0,
      flaggedCount: 0,
      bio: '',
      id: '',
      isPrivate: false,
    },
  },
}

const subjectInfoSlice = createSlice({
  name: 'subjectInfo',
  initialState: subjectInfoInitialState,
  reducers: {
    setSubjectInfo: (state, action) => {
      state.subjectInfo = action.payload
    },
    clearSubjectInfo: (state) => {
      state.subjectInfo = null
    },
    setCreatePostSelectedFlags: (state, action) => {
      state.selectedCreatePostFlags = action.payload
    },
    clearCreatePostSelectedFlags: (state) => {
      state.selectedCreatePostFlags =
        subjectInfoInitialState.selectedCreatePostFlags
    },
    setEditPostSelectedFlags: (state, action) => {
      state.selectedEditPostFlags = action.payload
    },
    clearEditPostSelectedFlags: (state) => {
      state.selectedEditPostFlags =
        subjectInfoInitialState.selectedEditPostFlags
    },
    setCurrentPlatform: (state, action) => {
      state.currentPlatform = action.payload
    },
    clearCurrentPlatform: (state) => {
      state.currentPlatform = subjectInfoInitialState.currentPlatform
    },
  },
})

export const {
  setSubjectInfo,
  clearSubjectInfo,
  setCreatePostSelectedFlags,
  clearCreatePostSelectedFlags,
  setCurrentPlatform,
  clearCurrentPlatform,
  setEditPostSelectedFlags,
  clearEditPostSelectedFlags,
} = subjectInfoSlice.actions

export default subjectInfoSlice.reducer

import { useEffect } from 'react'

import { WeScreen } from '@/components/WeScreen/WeScreen'
import { useAppSelector } from '@/hooks/redux'
import { useRedirectBasedOnPermissions } from '@/hooks/useRedirectBasedOnPermissions'
import { useUsersQuery } from '@/store/api/Admin/Admin.query'
import { selectItsDefaultState } from '@/store/api/Users/Users.selector'

import { UsersTable } from './Components/UsersTable'

export const Users = () => {
  const { hasHandleUsers } = useRedirectBasedOnPermissions()
  const itsDefaultState = useAppSelector(selectItsDefaultState)
  const { data, isLoading, isError } = useUsersQuery()

  useEffect(() => {
    if (!itsDefaultState) hasHandleUsers()
  }, [itsDefaultState])

  return (
    <WeScreen
      title="Users"
      useHeaderBackButton={false}
      useNextButton={false}
      isError={isError}
      isLoading={isLoading}
    >
      <UsersTable data={data || []} />
    </WeScreen>
  )
}

import React from 'react'

interface SocialMetricsProps {
  following: number
  followers: number
  posts: number
  platform?: string
}

export const SocialMetrics = ({
  following,
  followers,
  posts,
  platform,
}: SocialMetricsProps) => {
  return (
    <div className="flex flex-row gap-5">
      {platform === 'WebSearch' ? (
        <>
          <div className="flex gap-1 max-md:flex-col max-md:items-center">
            <p className="text-textNormal text-base font-bold tracking-tight">
              {posts}
            </p>
            <p className="text-textAccent text-base font-normal tracking-tight">
              dashboard.header.posts
            </p>
          </div>
        </>
      ) : (
        <>
          <div className="flex gap-1 max-md:flex-col max-md:items-center">
            <p className="text-textNormal text-base font-bold tracking-tight">
              {followers}
            </p>
            <p className="text-neutralColor60 text-base font-normal tracking-tight">
              Followers
            </p>
          </div>
          <div className="flex gap-1 max-md:flex-col max-md:items-center">
            <p className="text-textNormal text-base font-bold tracking-tight">
              {following}
            </p>
            <p className="text-neutralColor60 text-base font-normal tracking-tight">
              Following
            </p>
          </div>
          <div className="flex gap-1 max-md:flex-col max-md:items-center">
            <p className="text-textNormal text-base font-bold tracking-tight">
              {posts}
            </p>
            <p className="text-neutralColor60 text-base font-normal tracking-tight">
              Posts
            </p>
          </div>
        </>
      )}
    </div>
  )
}
